@import "../../../theme/colors";

.salesforce-title {
  font-size: 22px;
  color: $additional-text-color;
  font-weight: 500;
  padding: 5px;
  margin-bottom: 15px;

  span {
    color: $parent-second-color;
    font-weight: 600;
  }
}

.salesforce-fields {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  border-top: 2px solid #EFEFEF;

  .salesforce-field-row {
    max-width: 100%;
    margin: 5px 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .salesforce-field-body {
      border: 1px solid $additional-text-color;
      padding: 15px;
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);

      .salesforce-field-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .salesforce-info-value {
          display: flex;
          flex-direction: column;
          width: 33.33%;

          label {
            width: 95%;
            color: $additional-text-color;
            margin-bottom: 5px;
          }

          input {
            width: 95%;
            padding: 5px 5px;
            border-radius: 5px;
            border: 1px solid $parent-second-color;
            color: $primary-text-color;
            opacity: 0.7;
            background-color: #e7e7e7;
          }

          .salesforce-related-questions {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: row;
            width: 100%;

            span {
              transition: 0.3s;
              padding: 2px 5px;
              margin-bottom: 5px;
              background: white;
              border-radius: 15px;
              font-size: 14px;
              margin-right: 5px;
              border: 1px solid $parent-second-color;
            }

            span:hover {
              transition: 0.3s;
              cursor: default;
              background-color: $parent-second-color;
              color: white;
            }
          }
        }
      }
      .salesforce-field-content {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        label {
          color: $additional-text-color;
          margin-bottom: 5px;
        }
        input {
          padding: 10px 5px;
          border-radius: 5px;
          border: 1px solid $parent-second-color;
          font-size: 15px;
          color: $primary-text-color;
        }
      }
    }
  }
}
@media (max-width: 1250px) {
  .salesforce-title {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .salesforce-info-value {
    width: 100% !important;
    margin-bottom: 10px;
  }
}