.notification {
  margin: 10px;
  opacity: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #f5f5f5;
  border-left: 8px solid #007BFF;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 4px;
  padding: 15px;
  transition: all 0.3s ease-out;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  animation: fadeIn 0.5s forwards;
  .notification-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .notification-message {
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: inherit;
}

.notification.success {
  background-color: #d4edda;
  color: #155724;
  border-left-color: #28a745;
}

.notification.error {
  background-color: #f8d7da;
  color: #721c24;
  border-left-color: #dc3545;
}

.notification.info {
  background-color: #cce5ff;
  color: #004085;
  border-left-color: #17a2b8;
}

.notification.warning {
  background-color: #fff3cd;
  color: #856404;
  border-left-color: #ffc107;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
