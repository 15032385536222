@import "../../theme/colors";

.mark-container {
  position: absolute;
  top: -2px;
  right: 10px;
  border: 1px solid #FFFFFF;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 5px 21px;
  z-index: 99;
  > span {
    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }
}
.reserved {
  background: $conab-danger-color;
}

.newDonor {
  background: $parent-primary-color;
}