@import "../../theme/colors";

body {
    background-color: #FAFAFA;
    font-family: NunitoSans, sans-serif;
    font-style: normal;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

#root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > .App {
        box-sizing: border-box;
        width: 100%;

        > .wrapper {
            max-width: 100%;
        }
    }
}

.link {
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #006485;
    text-decoration: underline;
    cursor: pointer;
}

.color-purple {
    color: #ad1b5f!important;
}

.color-green {
    color: #1a7b74!important;
}

.color-blue {
    color: #004a63!important;
}

.color-gold {
    color: #846f45!important;
}

.color-red {
    color: #d8384f!important;
}

button, input[type='button'] {
    cursor: pointer;
}

@media (max-width: 1250px) {
    html {
        min-height: 100%;
        height: 100%;
    }

    body {
        min-height: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
    }

    .button, input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

.header-bg-surrogate {
    nav ul li {
        &.parents a, &.surrogates a, &.egg_donors a, &.about_us a {
            color: #ffffff!important;
        }
    }

    background-color: $surrogate-app-color !important;

    .user-profile {
        background-color: $surrogate-app-second-color !important;
        border-bottom: 0!important;
        height: 78px!important;
        color: #FFFFFF!important;
    }
}

.bg-egg_donor nav ul li {
    &.parents a, &.surrogates a, &.egg_donors a, &.about_us a {
        color: $primary-text-color;
    }
}

.bg-surrogate nav ul li {
    &.parents a, &.surrogates a, &.egg_donors a, &.about_us a {
        color: #ffffff!important;
    }
}

.header-bg-parent {
    nav ul li {
        &.parents a, &.surrogates a, &.egg_donors a, &.about_us a {
            color: #ffffff!important;
        }
    }

    background-color: $parent-app-color !important;

    .user-profile {
        background-color: $parent-app-second-color !important;
        border-bottom: 0!important;
        height: 78px!important;
        color: #FFFFFF!important;
    }
}

.header-bg-egg_donor {
    background-color: $egg-donor-app-second-color !important;

    .user-profile {
        background-color: $egg-donor-app-color !important;
        border-bottom: 0!important;
        height: 78px!important;
        color: #FFFFFF!important;
    }
}

.user-avatar {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

input[type="submit"] {
  cursor: pointer;
}
