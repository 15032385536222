@import "../../../theme/colors";

.infinite-scroll {
  overflow-y: auto;
  flex-grow: 1;
  max-width: 100%;

  .loading {
    display: flex;
    justify-content: center;
    min-width: 100%;
    margin: 40px 0 40px 0;

    svg {
      width: 50px;
      height: 50px;
    }
  }
  .total-info {
    position: absolute;
    top: -45px;
    left: 0;
    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    color: $secondary-text-color;
    z-index: 99;
  }
}

.persons-count {
  padding: 15px 38px 0 38px;
  color: $secondary-text-color;
  font-family: 'NunitoSans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
}

.survey-profile {
  display: grid;
  grid-template-columns: repeat(auto-fill, 358px);
  grid-gap: 10px;
  position: relative;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: auto;
  padding-bottom: 110px;

  .header-layout {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
  }

  .person {
    position: relative;
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #CFCFCF;
    border-radius: 3px;
    min-width: 333px;
    max-width: 333px;
    cursor: pointer;
  }
  .reserved-banner {
    grid-row: 1 / -1;
    grid-column: -1 / 2;
  }

  .person-image {
    position: relative;
    width: auto;
    height: 333px;
    background-color: $additional-text-color;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .like-donor {
      position: absolute;
      right: 18px;
      bottom: 18.65px;
      width: 20px;
      height: 18.35px;
      background: url(../../../resources/images/heart.svg) no-repeat center;
      transition: transform 0.3s ease-in-out, background-image 0.3s ease-in-out;
      &.liked {
        background: url(../../../resources/images/heart-active.svg) no-repeat center;
        animation: heart-beat 1s ease-in-out;
      }
      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .person-information {
    list-style-type: none;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    div {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      color: $secondary-text-color;
      font-style: normal;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.0120588em;

      span {
        white-space: normal;
        color: $primary-text-color;
        width: 160px;
      }
    }

    .personal-number {
      color: $parent-primary-color;
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
}

@media (max-width: 1250px) {
  .survey-profile {
    padding-bottom: 50px;
    margin-top: 25px;

    .person {
      margin: 0 auto 20px;
    }
    .reserved-banner {
      grid-column: auto;
      margin-bottom: 40px;
    }
  }
}

@keyframes heart-beat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

