@import "../../../theme/colors";

.drop-down-container {
    width: 100%;
    position: relative;

    > {
        .drop-down > {
            img {
                position: absolute;
                right: 14px;
                top: 28px;
                cursor: pointer;
            }

            input[type="text"] {
                cursor: pointer;
                margin-bottom: 0!important;
                color: $primary-text-color;
            }
        }

        .drop-down-items {
            max-height: 306px;
            overflow-y: auto;
            position: absolute;
            background: #FFFFFF;
            box-shadow: 0 0 36px rgba(128, 154, 152, 0.25);
            border-radius: 4px;
            z-index: 80;
            right: 0;
            left: 0;

            ul {
                > li {
                    font-family: NunitoSans, sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: $primary-text-color;
                    padding-bottom: 10px;
                    cursor: pointer;
                    position: relative;
                    text-align: left;
                    display: flex;
                    justify-content: space-between;
                    padding-top: 12px;
                    padding-left: 20px;
                    &:hover {
                        background: #D5D5DA;
                    }

                    > span {
                        margin-right: 20px;
                        display: flex;

                        img:first-child {
                            margin-right: 8px;
                        }
                    }

                    &.item-selected {
                        font-family: NunitoSans, sans-serif;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}

.language-dropdown {
    label {
        width: 100%;
        float: left;
        margin-bottom: 9px;
        font-size: 15px;
        color: $primary-text-color;
    }
    input {
        width: 100%;
        max-width: 235px;
        height: 55px;
        background: rgba(246, 246, 246, 1);
        border-radius: 5px;
        border: 1px solid rgba(233, 233, 233, 1);
        font-size: 15px;
        padding-left: 24px;
    }
    img {
        width: 12px;
        height: 6px;
        top: 50px !important;
        right: 40px !important;
    }
}

.language-finished {
    label {
        text-align: left !important;
        padding-left: 50px !important;
    }
    img {
        right: 70px !important;
    }

}

@media (max-width:1250px) {
    .language-finished {
        label {
            padding-left: 35px !important;
        }

        img {
            right: 55px !important;
        }
    }
}
