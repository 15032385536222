.tabs {
  margin-top: 20px;

  .tab-list {
    display: flex;
    width: 100%;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding-left: 0;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    width: 50%;
    height: 55px;
    cursor: pointer;
    text-align: center;
    vertical-align: center;
    line-height: 55px;
  }

  .active {
    border-bottom: 3px solid #816F4B;
    color: #816F4B;
    font-weight: 700;
  }
}

