.modal-dialog {
  width: 500px;
  min-height: 120px;
  max-height: max-content;
  background: #FFFFFF;
  padding: 15px 10px;
  border-radius: 3px;
  border: 1px solid #eee;
  z-index: 7777;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  > {
    .header {
      display: flex;
      width: 100%;
      padding: 3px 16px;
      order: 0;
      box-sizing: border-box;

      > {
        .title {
          width: 90%;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 41px;
          color: #57575C;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .close {
          width: 10%;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          > img {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
        }
      }
    }

    .content {
      display: flex;
      width: 100%;
      padding: 46px 16px 0;
      order: 2;
      box-sizing: border-box;
      height: 100%;
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .button {
    background: #006485;
    border-radius: 36px;
    width: 100%;
    height: 44px;
    font-size: 14px;
    letter-spacing: 0.0120588em;
    text-align: center;
    outline: none;
    border: 0;
    margin-top: 15px;
    color: #FFFFFF;
  }

  .disabled {
    background: #EAEAEE;
    color: #979797;
  }
}

@media (max-width: 1250px) {
  .modal-dialog {
    width: 325px;
  }

  .modal-dialog > .header {
    padding-top: 12px;
  }
}

@media (max-width: 500px) {
  .modal-dialog {
    width: 225px;
    padding: 5px;

    .content {
      padding: 23px 8px 0;
    }
  }
  .modal-dialog > .header {
    padding-top: 16px;
  }
}
