@import "../../theme/colors";

.photo-upload {
  position: relative;
  .upload-container {
    position: relative;
    background: #FAFAFA;
    border: 1px dashed #C0BABA;
    border-radius: 5px;
    cursor: pointer;
    height: 383px;
    width: 460px;
    .loading-spinner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      .progress-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 345px;
        margin-bottom: 16px;
        .progress-info {
          display: flex;
          align-items: center;
          .text {
            font-family: 'NunitoSans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $primary-text-color;
            margin-left: 6px;
          }
        }
      }
    }
  }

  .upload-label {
    display: block;
    height: 100%;
    width: 100%;
    cursor: pointer;
    .upload-input {
      display: none;
    }
    .label-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .title {
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 23px;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 5px;
      color: $primary-text-color;
      .blue-text {
        color: #007AFF;
      }
    }

    .size-text {
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.0120588em;
      text-align: center;
      color: $additional-text-color;
    }
    .types-text {
      font-size: 13px;
    }
    .loaded-count {
      font-size: 12px;

    }
  }

  .preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 460px;
    margin-top: 10px;
    .preview-wrapper {
      position: relative;
      &:hover .preview-photo {
        opacity: 0.8
      }
    }
    .preview-photo {
      width: 100px;
      height: 100px;
      object-fit: cover;
      cursor: pointer;
      transition: opacity 0.1s ease-in-out;
    }

    .remove-photo {
      position: absolute;
      top: 4%;
      right: 3%;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background-color: white;
      border-radius: 50%;
    }
  }

  .single-preview {
    position: absolute;
    top: -8px;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 100%;
    height: 400px;
    .avatar-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .loading-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
      }
      .avatar-editor {
        background: #7f7f7f;
      }
    }
  }

  .single-photo {
    background: #fff;
  }

  .single-buttons {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
    .btn-cancel {
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      border: none;
      text-align: center;
      letter-spacing: 0.0120588em;
      width: 147px;
      padding: 8px;
      color: $additional-text-color;
      cursor: pointer;

      background: #EAEAEE;
      border-radius: 36px;
      margin-right: 10px;
    }

    .btn-save {
      font-family: 'NunitoSans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      border: none;
      text-align: center;
      letter-spacing: 0.0120588em;
      width: 147px;
      padding: 8px;
      color: #FFFFFF;
      cursor: pointer;

      background: $conab-danger-color;
      border-radius: 36px;
    }
  }
}

@media (max-width: 1250px) {
  .progress {
    max-width: 290px;
  }

  .loading-spinner {
    padding: 0 15px;
    .progress-content {
      max-width: 345px;
      width: 100% !important;
    }
    .progress-container {
      max-width: 345px;
      width: 100% !important;
    }
  }

  .upload-answer {
    width: 100%;
    .content {
      width: 100%;
      .photo-upload {
        .upload-container {
          width: 100%;
          max-width: 320px;
          margin: 0 auto;
        }
        .preview-container {
          width: 100%;
          max-width: 320px;
          margin: 0 auto;
          gap: 10px;
          padding-top: 10px;
        }
      }
    }
  }
}
