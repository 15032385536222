@import "../../theme/colors";

body {
    overflow: hidden;
}

.row {
    display: flex;
    height: 100vh;
}

.security > .left-column {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

.security-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.left-column > {
    header {
        align-self: flex-start;

        > {
            .app-logo {
                padding: 17px 0 0 24px;
                width: 176px;
                height: 43px;
                cursor: pointer;
            }

            .app-menu {
                display: none;
            }
        }
    }

    .security-form > {
        h1 {
            font-weight: 800;
            font-size: 32px;
            line-height: 41px;
            color: #173432;
        }

        .security-form-description {
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-top: 40px;
        }
    }
}

.security .right-column {
    background: #FFFFFF;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .security-right-image {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        -o-object-fit: scale-down;
    }
}

.left-column .security-form .form {
    .button {
        background: #006485;
        border-radius: 36px;
        width: 100%;
        height: 44px;
        font-size: 14px;
        letter-spacing: 0.0120588em;
        text-align: center;
        outline: none;
        border: 0;
        margin-top: 15px;
        color: #FFFFFF;
        &:focus {
          outline: 1px solid blue;
        }
    }


    .disabled {
        background: #EAEAEE;
        color: $additional-text-color;
    }

    .form-item {
        .field-error {
            border: 1px solid #FB4E4E;
        }

        .form-error {
            font-size: 15px;
            line-height: 16px;
            color: #FB4E4E;
            margin-top: 6px;
        }
    }
}

@media (max-width: 1250px) {
    body {
        overflow-x: hidden;
        overflow-y: auto;
        position: -webkit-sticky;
        display: block;
    }

    .row {
        flex-direction: column;
    }

    .left-column {
        > {
            .security-form {
                margin: 56px auto;
                width: 333px;
                position: relative;
            }

            header {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                height: 41px;
                width: 100%;
                background: #FFFFFF;
                box-shadow: 0 4px 4px #F4F4F4;
                z-index: 9999;

                > {
                    .app-logo {
                        width: 185px;
                        height: 41px;
                        padding: 0;
                    }

                    .app-menu {
                        display: block;
                        float: right;
                        margin-top: 15px;
                        margin-right: 21px;
                    }
                }
            }
        }

        .security-form .form .button {
            border-radius: 3px!important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }

    .column {
        width: 100%;
        flex: 1 1;
    }
}

@media (max-width: 350px) {
    .left-column > .security-form {
        width: 300px;
    }
}
