.wrapper >  header {
    > {
        .mobile {
            display: none;
            padding-left: 7px;
        }

        .desktop {
            display: flex;
            > {
                .left-column {
                    //width: 200px;
                    order: 1;
                    display: flex;
                    align-items: center;
                }

                .right-column {
                    width: 480px;
                    display: flex;
                    align-items: center;
                    order: 3;
                    justify-content: flex-end;
                }

                .center-column {
                    flex: 1;
                    order: 2;
                }

                .left-column > a > .app-logo {
                    width: 176px;
                    height: 43px;
                    margin-left: 24px;
                }

                .center-column {
                    display: flex;
                    align-items: center;
                }

                .right-column > {
                    .app-search {
                        margin-right: 40px;
                        position: relative;

                        > {
                            img {
                                position: absolute;
                                top: 9px;
                                left: 14px;
                                user-select: none;
                            }

                            input[type='text'] {
                                background: #EEEFF3;
                                border-radius: 28px;
                                width: 210px;
                                height: 37px;
                                padding-left: 47px;
                                border: 2px solid #fff;
                                box-sizing: border-box;

                                &:focus {
                                    border: 2px solid #717487;
                                }
                            }
                        }
                    }

                    .user-profile {
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 23px;
                        color: #22232E;
                        margin-right: 19px;
                        height: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 17px 0 16px;
                        border-bottom: 8px solid #717487;
                        cursor: pointer;

                        > a {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 23px;
                            color: #22232E;
                            text-decoration: none;
                        }

                        &.cursor-default {
                            cursor: default;
                        }
                    }

                    .globe-icon {
                        margin-right: 21px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    background: #FFFFFF;
    border-bottom: 1px solid #DBDBDB;
    box-sizing: border-box;
    height: 80px;
}

.int-links {
    right: 44px;
    position: absolute;
    text-align: center;
    top: 44px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: -1px -2px 3px #eeeff3;
    opacity: .95;
    width: 12em;
    z-index: 99999;
    display: block;

    ul li {
        padding: .8rem 1rem;
        cursor: pointer;

        &:hover {
            background-color: #EFF0F4;
        }

        a {
            text-decoration: none;
            color: #22232e;
        }
    }
}

.profile-menu {
    position: absolute;
    z-index: 99999;
    top: 81px;
    right: 83px;
    width: 206px;
    height: 96px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    > ul > li {
        height: 48px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 16px;
        &:hover {
          background: #D5D5DA;
        }
        &:first-child {
            border-bottom: 1px solid #D7D7D7;
        }

        > a {
            width: 100%;
            padding-top: 12px;
            padding-bottom: 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
            text-decoration: none;
        }
    }
}

@media (max-width: 1250px) {
    .wrapper > header {
        > {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;

                > {
                    a > .app-logo {
                        width: 185px;
                        height: 41px;
                        padding: 0;
                    }

                    nav {
                        display: inline;

                        > .app-menu {
                            display: block;
                            float: right;
                            padding-top: 15px;
                            padding-right: 21px;
                        }
                    }
                }
            }
        }

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 41px;
        width: 100%;
        background: #FFFFFF;
        z-index: 9999;
        border: 0;
    }
}
