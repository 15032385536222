@import "../../../../theme/colors";


.summary-container {
  display: flex;
  flex-direction: column;
  .block-title {
    font-family: NunitoSans, sans-serif;
    font-size: 21px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.0121em;
    text-align: left;
    color: #173A4E;
    margin: 43px 0 24px;
  }
  .basic-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    > .donor-id {
      font-family: "NunitoSans", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0.0121em;
      text-align: left;

      color: $parent-primary-color;
    }
  }
    .main-info-container {
    .main-info {
      position: relative;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .pdf-container {
        transition: 0.3s;
        position: relative;
        display: flex;
        gap: 13px;
        cursor: pointer;
        min-width: 110px;
        >img {
          height: 24px;
          width: 24px;
        }
        .pdf-text {
          font-family: "NunitoSans", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0.0121em;
          text-align: left;
          color: $parent-primary-color;
        }
      }
    }
      .column {
        flex-direction: column;
      }
      .pdf-space {
        flex-direction: row;
        gap: 100px;
      }
      .drop-down {
        input {
          background: #f6f6f6;
          border: 1px solid #e9e9e9;
          box-sizing: border-box;
          border-radius: 4px;
          height: 55px;
          width: 130px;
          padding: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 23px;
          display: flex;
          align-items: center;
          letter-spacing: 0.0120588em;
        }
      }
      .yes_no {
        background: #F6F6F6;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 3px;
        width: 80px;
        margin-bottom: 16px;
        font-style: normal;
        height: 55px;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.0120588em;
        color: #173a4e;
        padding-top: 6px;
        padding-bottom: 6px;
        transition: background-color 0.1s ease-out;
        margin-right: 10px;

        &.selected {
          background: #F6F6F6;
          border: 1.5px solid #006485;
          box-sizing: border-box;
          border-radius: 3px;
        }
        &:hover {
          background: #D5D5DA;
        }
      }
  }
  .edit-box {
    > p {
      font-family: "NunitoSans", sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: #020202;
      margin-bottom: 9px;
    }
    > input, textarea {
      color: #173a4e;
      background: #F6F6F6;
      border: 1px solid #E9E9E9;
      box-sizing: border-box;
      border-radius: 4px;
      height: 55px;
      min-width: 300px;
      width: 100%;
      padding: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 23px;
      display: flex;
      align-items: center;
      letter-spacing: 0.0120588em;
      transition: all 0.3s ease;
      &:focus {
        outline: none;
        border-color: $border-grey-color;
      }

      &:hover {
        border-color: #959595;
      }
    }
    textarea {
      box-sizing: border-box;
      font-family: "NunitoSans", sans-serif;
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
      padding: 8px 12px 60px;
      width: 99%;
      resize: none;
      height: 98px;
      margin-top: 10px;
      background-color: #F6F6F6;
      border-radius: 8px;
      border: 1px solid $border-grey-color;
      white-space: pre-wrap;
    }
    .multiselect-dropdown-container {
      width: 200px;
      .searchWrapper {
        height: 55px;
        overflow-y: unset;
      }
      .checkbox {
        accent-color: #397974 !important;
      }
      .chip:nth-child(n):not(:nth-last-child(2)) {
        &::after {
          content: "";
        }
      }
    }
    margin-bottom: 20px;
    .disabled-input {
      color: #746969;
    }
  }
  .disabled-block {
    > p {
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.0121em;
      text-align: left;
      color: #6A6868;
    }
    > input {
      color: #746969;
      &:hover {
        border: 1px solid $border-grey-color;
      }
    }
  }
  .compensation-block {
    input[type=number] {
      min-width: max-content;
    }
    .full-width {
      flex: 1 1 100%;
      input {
        max-width: 200px;
      }
    }
    .sub-title {
      font-family: NunitoSans, sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }
}

.red {
  color: $surrogate-app-color;
}