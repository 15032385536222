.video {
  height: 100%;
  width: 100%;
  position: relative;

  .video-element {
    width: 100%!important;
    height: 100%!important;
    object-fit: cover;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .watch-video {
    font-family: NunitoSans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 41px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 10;

    img {
      margin-right: 12px;
    }
  }
}

.video-full-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);

  .close-full-screen {
    position: absolute;
    top: 31px;
    right: 50px;
    cursor: pointer;
    z-index: 9999;
    user-select: none;
  }

  .video-placeholder {
    position: absolute;
    top: 70px;
    left: 40px;
    right: 50px;
    bottom: 50px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    video {
      width: 100%!important;
      height: 100%!important;
      object-fit: cover;
      outline: none;
    }
  }
}

@media (max-width: 1250px) {
  .video {
    display: none;
  }
}
