@import "../../theme/colors";

.columns > .center-column {
    display: flex;
    justify-content: center;
}

.start-application > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    letter-spacing: 0.0120588em;
    color: $additional-text-color;
    margin-top: 49px;
    margin-bottom: 39px;
}

h1.application-process-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 41px;
    letter-spacing: 0.0120588em;
    color: $primary-text-color;
    margin-bottom: 26px;
}

.start-application > {
    .start-banner {
        width: 575px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        padding-bottom: 20px;
    }

    .start-banner-green {
        background: radial-gradient(50% 50% at 50% 50%, #5F944E 0%, #69A457 100%);
    }

    .start-banner-blue {
        background: radial-gradient(50% 50% at 50% 50%, #94CAE4 0%, #81BBD7 100%);
    }

    .start-banner-grey {
        background: radial-gradient(50% 50% at 50% 50%, #717485 0%, #717485 100%);
    }

    .start-banner > div.bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../resources/images/start-banner-bg.svg);
    }

    .start-banner-blue > div.bg {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../resources/images/start_bg_dark_blue.svg);
        background-repeat: no-repeat!important;
    }

    .start-banner-grey > div.bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../resources/images/start_banner_grey.svg);
    }

    .start-banner {
        padding-top: 25px;
        padding-left: 59px;
        box-sizing: border-box;

        > {
            h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 40px;
                letter-spacing: 0.0120588em;
                color: #FFFFFF;
                margin-bottom: 10px;
            }

            p {
                position: inherit;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.0120588em;
                color: #FFFFFF;
                width: 286px;
                margin-top: 3px;
                margin-bottom: 10px;
              .clock-wrapper {
                display: flex;
                align-items: flex-end;
                margin-top: 20px;
                > img {
                  margin-right: 12px;
                }
              }
            }

            div.time {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.0120588em;
                color: #FFFFFF;

                > label {
                    margin-left: 17px;
                }
            }
        }
    }

    .start-app-description,
    .banner-description {
        width: 575px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: $additional-text-color;
        margin-top: 49px;
    }

    .start-button {
        background: $conab-primary-color;
        border-radius: 36px;
        width: 333px;
        height: 44px;
        font-size: 14px;
        letter-spacing: 0.0120588em;
        text-align: center;
        outline: none;
        border: 0;
        margin-top: 21px;
        color: #FFFFFF;
        margin-bottom: 21px;
    }
}

@media (max-width: 1250px) {
    .start-application {
      padding-bottom: 60px;
        width: 333px;

        > {
            .start-banner {
                width: 333px;

                > p {
                    width: 211px;
                }

                padding-left: 18px;
            }

            .start-app-description,
            .banner-description {
                width: 333px;
            }

            .start-banner > div.bg {
                background: url(../../resources/images/start-banner-bg-mobile.svg) no-repeat;
                background-position-y: bottom;
            }

            .start-banner-blue > div.bg {
                background: url(../../resources/images/start_bg_dark_blue_mobile.svg) no-repeat;
                background-position-y: bottom;
                background-position-x: 160px;
            }

            .start-banner-grey > div.bg {
                background: url(../../resources/images/start_bg_grey_mobile.svg) no-repeat;
                background-position-y: bottom;
                background-position-x: 170px;
            }

            .start-banner-green > div.bg {
                background: url(../../resources/images/start_bg_blue_mobile.svg) no-repeat;
                background-position-y: bottom;
                background-position-x: 160px;
            }

            .start-banner > div.time {
                margin-top: 24px;
            }
        }
    }

    .right-column-surrogate .care-team {
        display: block;
        margin-left: 0;
        margin-bottom: 40px;
    }

    .start-app-description .survey-process .app-survey-timeline {
        display: block;
    }
}

@media (max-width: 350px) {
    .start-application {
        > {
            h1 {
                text-align: center;
            }

            .start-banner, .start-app-description, .start-button, .banner-description {
                width: 290px;
            }
        }

        width: 290px;
    }
}
