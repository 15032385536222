@import "../../theme/colors";

.left-column .registration-form {
  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    display: flex;
    align-items: center;
    letter-spacing: .032em;
    color: $primary-text-color;
    user-select: none;
  }
}

@media (max-width: 1250px) {
  .left-column .registration-form {
    .title {
      font-size: 30px;
      line-height: 41px;
      display: flex;
      align-items: center;
      letter-spacing: 0.0120588em;
      color: $primary-text-color;
      user-select: none;
      cursor: pointer;
      padding-top: 22px;
    }
  }
}
