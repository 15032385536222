.calendly-box {
    position: absolute;
    left: calc(50% - 450px);
    top: calc(50% - 400px);
    width: 900px;
    height: 800px;
}

.close-overlay {
    color: white;
    text-align: right;
    position: relative;
    top: 25px;
    right: 25px;
    font-size: 25px;
    cursor: pointer;
}

.calendly-wrapper .overlay {
    background: rgba(0, 0, 0, 0.7);
}
