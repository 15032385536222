//-- Text colors
$primary-text-color: rgba(23, 58, 78, 1);
$secondary-text-color: rgba(172, 160, 160, 1);
$additional-text-color: rgba(87, 87, 92, 1);
$black-text-color: #000;

//-- Parent colors
$parent-app-color: rgba(113, 116, 133, 1);
$parent-app-second-color: rgba(62, 65, 81, 1);
$parent-primary-color: rgba(129, 111, 75, 1);
$parent-second-color: rgb(105, 89, 61);

//-- Surrogate colors
$surrogate-app-color: rgba(160, 44, 94, 1);
$surrogate-app-second-color: rgba(113, 31, 67, 1);

//-- Egg Donor colors
$egg-donor-app-color: rgba(57, 121, 116, 1);
$egg-donor-app-second-color: rgba(37, 80, 76, 1);

//-- Survey colors
$survey-info-bg: #89C1DD;
$survey-info-question-empty: #A8D5EB;
$survey-info-question-answered: white;

$survey-info-parent-bg: rgba(129, 111, 75, 1);
$survey-info-parent-question-empty: rgba(233, 223, 203, 1);
$survey-info-parent-question-answered: rgba(255, 255, 255, 1);

//-- ConabApp Main Colors
$conab-primary-color: #006485;
$conab-danger-color: rgba(200, 70, 83, 1);
$border-grey-color: #E9E9E9;

