.survey-review {
  border-radius: 4px;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 30px;
  background: #6A3BB8 url(../../resources/images/review-background.svg) no-repeat;
  background-position-x: 11px;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.0120588em;
    color: #FFFFFF;
    width: 307px;
  }

  p {
    width: 280px;
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.0120588em;
    color: #FFFFFF;
  }
}