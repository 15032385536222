.question-tooltip {
  position: absolute;
  z-index: 100;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #816F4B;
  padding: 15px 10px;
  min-width: 350px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.02);

  p {
    margin-bottom: 5px;
    width: 100%;
    font-size: 14px;
    color: #766b89;

    span {
      margin-right: 5px;
      font-weight: 600;
      color: #816F4B;
    }
  }
}