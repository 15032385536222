.photo-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin: 49px 0 0 0;
  width: 60%;
  padding-bottom: 80px;

  .photo-item {
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: 0 0 16px 0;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 2px;

    .image-wrapper {
      position: relative;
      width: 100%;
      background-color: #717485;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .remove-gallery-photo {
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }

      .swap-gallery-photo {
        transition: 0.2s;
        background: white;
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 5px;
        position: absolute;
        top: 5px;
        left: 5px;
      }
      .swap-gallery-photo:hover {
        transition: 0.2s;
        cursor: pointer;
        background: #77fff8;
      }
    }

    .photo-description{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      min-height: 68px;
      height: auto;
      background: url(../../../resources/images/description.svg) no-repeat 33px center;

      span {
        color: black;
        margin: 0 0 0 69px;
      }
      input[type="text"] {
        font-style: normal;
        font-size: 15px;
        width: 60%;
        text-align: left;
        border: 1px solid #E9E9E9;
        border-radius: 4px;
        padding: 10px;
        height: auto;
        margin: 0 0 0 59px;
      }
      .admin-icons {
        padding-right: 20px;
        cursor: pointer;
      }
    }
  }
  .add-photo {
    padding: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .add-photo-hidden {
    display: none;
  }
  .modal-dialog {
    .content {
      padding: 10px;
    }
    .modal-buttons {
      display: flex;
      margin-top: 14px;
      .cancel-remove {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.0120588em;

        color: #FB4E4E;
        background: #fff;
        width: 50px;
        border: 1px solid #FB4E4E;
        border-radius: 42px;
        cursor: pointer;

        transition: color, background-color 0.1s ease-out;
        &:hover {
          background: #c84653;
          color: #fff;
        }
      }
      .confirm-remove {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.0120588em;

        color: #FFFFFF;
        background: #816F4B;
        border-radius: 42px;
        width: 50px;
        border: none;
        margin-left: 10px;
        cursor: pointer;

        transition: color, background-color 0.1s ease-out;
        &:hover {
          background: #6d5f41;
        }
      }
    }
  }
}



@media (max-width: 1012px) {


  .photo-list-wrapper {
    width: auto;
    max-width: 100%;
    margin: 25px auto;
    padding-bottom: 65px;

    .photo-item {
      width: 100%;
      max-width: 350px;
      .photo-description {
        .admin-icons {
          padding-right: 10px;
        }
      }
    }
  }
}
