@import "../../theme/colors";

body {
    overflow: hidden;
}

.row {
    display: flex;
    height: 100vh;
}

.left-column {
    overflow-y: auto;
    width: 41%;

    .surrogate-image-mobile {
        display: none;
    }
}

.right-column {
    overflow-y: auto;
    width: 59%;
}
.left-column {
    .left-column-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .language-picker {
            margin-top: 15px;
            margin-right: 25px;

            .language-button {
                border: none;
                padding: 5px 20px 5px 20px;
                font-weight: 500;
            }

            .english-button {
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }

            .espanol-button {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }

            .active-language {
                background-color: $surrogate-app-color;
                color: white;
                font-weight: 600;
            }
        }
    }
}

.left-column > header > {
    .app-logo {
        padding: 17px 0 0 24px;
        width: 176px;
        height: 43px;
    }

    .app-menu {
        display: none;
    }
}

.video-column {
    width: 59%;
}

.description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $primary-text-color;
    margin-top: 20px;

    .first-paragraph,
    .second-paragraph {
        margin-top: 20px;
    }
}

.description-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    line-height: 22px;
}

.animated-gradient-parent {
    background: repeating-linear-gradient(to right, #816F4B 0%, #717487 50%, #816F4B 100%) 0 100%;
    width: 100%;
    background-size: 200% auto;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 1;
}

.animated-gradient-surrogate {
    background: repeating-linear-gradient(to right, #ad1b5f 0%, #717487 50%, #ad1b5f 100%) 0 100%;
    width: 100%;
    background-size: 200% auto;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 1;
}

.animated-gradient-egg_donor {
    background: repeating-linear-gradient(to right, #1a7b74 0%, #717487 50%, #1a7b74 100%) 0 100%;
    width: 100%;
    background-size: 200% auto;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 1;
}

@media (max-width: 1250px) {
    body {
        overflow-x: hidden;
        overflow-y: auto;
        position: -webkit-sticky;
        display: block;
    }

    .row {
        flex-direction: column;

        .left-column {
          overflow-y: unset;
          display: flex;

            .surrogate-image-mobile {
                overflow: hidden;
                display: flex;
                background-color: white;
                width: 100%;
                min-height: 360px;
                justify-content: center;

                img {
                    padding-top: 20px;
                    height: 360px;
                }

            }
        }
    }

    .left-column {
        .left-column-header {
            .language-picker {
                margin-top: 0;
                margin-right: 2px;
            }
        }
    }

    .left-column > header {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 41px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 4px 4px #F4F4F4;
        z-index: 9999;

        > {
            .app-logo {
                width: 185px;
                height: 41px;
                padding: 0;
            }

            .app-menu {
                display: block;
                float: right;
                margin-top: 15px;
                margin-right: 21px;
            }
        }
    }

    .security > .right-column {
        display: none;
    }
}

.colors-text-parent {
    color: $parent-primary-color !important;
}

.bg-parent {
    background-color: $parent-primary-color !important;
    transition: background-color 0.1s ease-out;
    &:hover {
      background-color: $parent-second-color !important;
    }
}

.colors-text-egg_donor {
    color: $egg-donor-app-color !important;
}

.bg-egg_donor {
  background-color: $egg-donor-app-color !important;
  transition: background-color 0.1s ease-out;
  &:hover {
    background-color: $egg-donor-app-second-color !important;
  }
}

.colors-text-surrogate {
    color: $surrogate-app-color !important;
}

.bg-surrogate {
    background-color: $surrogate-app-color !important;
    transition: background-color 0.1s ease-out;

    &:hover {
        background-color: $surrogate-app-second-color !important;
      }
}

