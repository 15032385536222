@media (max-width: 1250px) {
    .wrapper > section {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
}
