.logs-container {
  padding: 48px 99px;

  .title {
    font-family: "NunitoSans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0.0121em;
    text-align: left;
    color: #173A4E;

    span.id {
      color: #816f4b;
    }
  }

  table.logs-list {
    width: 100%;
    border-collapse: collapse;
    margin-top: 24px;

    th, td {
      text-align: left;
      padding: 16px;
      font-family: "NunitoSans", sans-serif;
      width: 250px;
      word-wrap: break-word ;
      > img {
        width: 80px;
        height: 80px;
      }
    }

    th {
      background-color: #F5F5F5;
      color: #173A4E;
      font-weight: 600;
    }

    tr {
      border-bottom: 1.5px solid #EFEFEF;

      &:hover {
        transition: background-color 0.1s ease-in-out;
        background-color: #f0f0f0;
      }
    }

    td {
      color: #4A4A4A;

      &.id {
        color: #816f4b;
      }
    }
    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    .diff-btn {
      opacity: 0.8;
      cursor: pointer;
      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
}
