.password-recovery .password-description {
  width: 100%;
  height: 60px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0120588em;
  color: #959595!important;
  margin-top: -16px;

  b {
    font-weight: bold;
  }
}
