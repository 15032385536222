.start-banner-lite {
  max-width: 575px;
  min-height: 300px;
  margin: 40px auto 0;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  background: radial-gradient(50% 50% at 50% 50%, #94CAE4 0%, #81BBD7 100%);

  .message-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: url(../../resources/images/start_bg_dark_blue.svg);

    .text-wrapper {
      padding: 25px 0 25px 60px;
      width: 300px;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.0120588em;
      color: #FFFFFF;

      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 20px;
      }

      .popup-button {
        border: 0;
        background: transparent;
        text-decoration: underline;
        color: #AD1B5F;
        font-size: 16px;
        padding: 10px 0 0 0;
      }
    }

    .next-survey-button {
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      width: 307px;
      margin-bottom: 20px;
      border: 0;
      font-style: normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.0120588em;
      color: #FFFFFF;
      padding: 6px;
      font-weight: bold;
      background: #A02C5E;
    }
  }

}
