.checkbox {
    input {
        display: none;
    }

    span {
        width: 12px;
        height: 12px;
        position: relative;
        top: 1px;
        display: inline-block;
        background: url("../../resources/images/checkbox_small.svg") no-repeat right center;
        background-size: contain;
    }

    input:checked + span {
        background: url("../../resources/images/checkbox_checked_small.svg") no-repeat right center;
        background-size: contain;
    }
}

.checkbox-egg_donor input:checked + span {
    background: url("../../resources/images/checkbox_checked_small_green.svg") no-repeat right center;
    background-size: contain;
}

.checkbox-parent input:checked + span {
    background: url("../../resources/images/checkbox_checked_small_gold.svg") no-repeat right center;
    background-size: contain;
}

.checkbox-surrogate input:checked + span {
    background: url("../../resources/images/checkbox_checked_small_pink.svg") no-repeat right center;
    background-size: contain;
}
