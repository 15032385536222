.number-answer > {
    .error-message {
        color: red;
        padding-bottom: 15px;
    }

    .error-input {
        color: red;
    }
}
