@import "../../../theme/colors";

.survey-profile-aside {
  margin-top: 50px;

  .back-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    cursor: pointer;

    .back {
      color: $additional-text-color;
      font-weight: bold;

      &:before {
        content: url(../../../resources/images/back.svg);
        vertical-align: middle;
        margin-right: 15px;
      }

    }
  }

  .filter-container,
  .selected-container,
  .admin-container {
    margin-top: 50px;

    .title {
      margin-left: 15px;
      font-family: NunitoSans, sans-serif;
      font-weight: 700;
      display: block;
      text-transform: uppercase;
      color: $secondary-text-color;
      margin-bottom: 15px;
    }

    .favourite-title,
    .reserve-title,
    .questions-title {
      transition: 0.3s;
      margin-left: 25px;
      font-family: NunitoSans, sans-serif;
      font-weight: 700;
      font-size: 15px;
      line-height: 23px;
      padding: 15px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: $primary-text-color;
      cursor: pointer;

      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .favourite-title:hover, .reserve-title:hover, .questions-title:hover {
      transition: 0.3s;
      background-color: #EFEFEF;
    }

    .select-title {
      width: calc(100% - 50px);
    }

    .favourite-count,
    .reserve-count {
      color: $secondary-text-color;
      text-align: center;
      width: 50px;
    }

    .reserve-count {
      visibility: hidden;
    }

    .filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 240px;
      height: 42px;
      cursor: pointer;
      margin: 30px 0 0 25px;
      padding: 0 45px;
      border: 1px solid $parent-primary-color;
      box-sizing: border-box;
      border-radius: 42px;
      color: $parent-primary-color;
      user-select: none;
      transition: color, background-color 0.1s ease-out;

      &:hover {
        color: #FFFFFF;
        background: $parent-primary-color;
      }
    }

  }
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 999;
  position: fixed;
  top: 79px;
  left: 0;
  bottom: 0;

  &.open {
    right: 0;
  }

  > .sidebar {
    background: #FFFFFF;
    box-sizing: border-box;
    width: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 50px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease-out;


    &.open {
      width: 430px;
    }

    > {
      .header {
        height: 80px;
        display: flex;
        margin: 15px 0 20px;
        justify-content: space-between;
        align-items: center;

        .title {
          margin-left: 20px;
          font-family: NunitoSans, sans-serif;
          font-size: 28px;
          font-weight: 700;
          color: $additional-text-color;
        }

        .close-button {
          background: url(../../../resources/images/close-icon.svg) no-repeat 3px center;
          cursor: pointer;
          width: 20px;
          height: 20px;
          margin-right: 20px;
        }
      }

      .content {
        margin: 0 45px;

        .filter-property {
          margin-bottom: 17px;

          &:nth-child(4) .searchBox {
            max-width: 160px;
          }

          .title {
            font-family: NunitoSans, sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: $primary-text-color;
          }

          .multiselect-dropdown-container {
            margin-top: 10px;
          }

          .reset-button {
            cursor: pointer;
            display: block;
            box-sizing: border-box;
            margin: 20px auto;
            text-align: center;
            width: 307px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.0120588em;
            color: $conab-danger-color;
            background: #fff;
            text-decoration: none;
            padding: 9px 35px;
            border: 1px solid $conab-danger-color;
            border-radius: 42px;
            transition: color, background-color 0.1s ease-out;

            &:hover {
              color: #fff;
              background-color: $conab-danger-color;
            }
          }
        }

        .filter-properties-horizontal {
          display: flex;
          justify-content: space-between;

          .filter-property {
            width: 50%;

            .search-wrapper {
              width: 165px;
            }

            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              & .searchBox {
                max-width: 108px;
              }
            }


            .city {
              cursor: pointer;
              background: #F6F6F6;
              border: 1px solid #E9E9E9;
              box-sizing: border-box;
              border-radius: 4px;
              height: 55px;
              width: 100%;
              padding: 16px 27px 16px 16px;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 23px;
              display: flex;
              align-items: center;
              letter-spacing: 0.0120588em;
              margin: 10px 0 0 0;
            }
          }
        }
      }

      .footer {
        .filter-btn {
          cursor: pointer;
          display: block;
          box-sizing: border-box;
          margin: 20px auto;
          text-align: center;
          border-radius: 36px;
          width: 307px;
          border: 0;
          font-style: normal;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.0120588em;
          color: #FFFFFF;
          background: $parent-primary-color;
          text-decoration: none;
          padding: 9px 35px;
        }
      }
    }
  }
}

.dropdown-city {
  display: flex;
  width: 100%;

  input {
    background: #F6F6F6;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    padding: 16px 12px;
    height: 22px;
    overflow-y: auto;
    width: 100%;
    white-space: nowrap;
    margin-top: 10px;

  }
}

.filter-field {
  display: flex;
  width: 100%;

  .donor-id-input {
    background: #F6F6F6;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
    padding: 16px 12px;
    height: 22px;
    overflow-y: auto;
    max-width: 300px;
    width: 100%;
    white-space: nowrap;
    margin-top: 10px;
  }
}



@media (max-width: 1250px) {
  .survey-profile-aside {
    .back-container {
      justify-content: flex-start;
    }

    .filter-container {
      .title {
        margin-left: 0;
      }

      .filter-btn {
        margin-left: 0;
      }
    }

    .selected-container, .admin-container {
      .title {
        margin-left: 0;
      }

      .favourite-title,
      .reserve-title,
      .questions-title {
        margin-left: 0;
      }
    }
  }
  .sidebar-overlay {
    top: 41px;
  }
}

@media (max-width: 500px) {

  .survey-profile-aside {
    margin-top: 40px;
    width: 100%;

    .back-container {
      padding: 4px 20px;
      justify-content: flex-start;
      background-color: #fff;
    }

    .filter-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      margin-top: 0;

      .title {
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
        margin: 0;
      }

      .filter-btn {
        width: 145px;
        padding: 0 5px;
        margin: 0;
      }
    }

    .selected-container, .admin-container {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding: 12px 20px;

      .title {
        text-align: center;
        margin-left: 0;
      }

      .favourite-title,
      .reserve-title,
      .questions-title {
        margin-top: 15px;
        justify-content: space-between;
        border-radius: 25px;

        .favourite-count,
        .reserve-count {
          margin-right: 0;
        }
      }
    }
  }
  .sidebar-overlay {
    top: 41px;

    > .sidebar {
      .open {
        max-width: 450px;
      }
      .content {
        padding-top: 10px;
        .filter-property {
          .reset-button {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }
}

.selected-item {
  background-color: #EFEFEF;
}

input {
  appearance: auto;
}
.yes-no-box {
  display: flex;
  gap: 50px;
  padding-top: 15px;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;

    .yes-no-checkbox {
      width: 24px;
      height: 24px;
      margin-right: 16px;
      accent-color: $parent-primary-color;
    }
    .yes-no-checkbox:before {
      width: 22px;
      height: 22px;
    }
    .yes-no-checkbox:after {
      width: 22px;
      height: 22px;
    }
  }
}