@import "../../theme/colors";

.multiselect-dropdown-container {
    width: 100%;
    position: relative;
    min-width: 100px;
    cursor: pointer;

  .multiSelectContainer {
    ul {
      max-height: 185px;
    }
    .searchWrapper {
      background: #F6F6F6;
      border: 1px solid #E9E9E9;
      border-radius: 4px;
      padding: 16px 12px;
      max-width: 340px;
      height: 57px;
      overflow-y: auto;
      white-space: nowrap;
      &:first-child {
        direction: ltr;
      }
      .chip:nth-child(n):not(:nth-last-child(2)) {
        &::after {
          content: ",";
        }
      }
    }
      .searchBox {
        width: 100%;
        cursor: pointer;
        max-width: max-content;
        input[type='text'] {
          border: none;
          margin: 0;
          display: inline;
          border-radius: 4px;
          cursor: pointer;
          &::placeholder {
            color: $primary-text-color;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.0120588em;
            opacity: 0.65;
          }
        }
      }
      .chip {
        font-family: 'NunitoSans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        color: $primary-text-color;
        padding: 0;
        background: transparent;
        margin: 0 1px;
      .closeIcon {
        display: none;
      }
    }
    .optionListContainer {
      .optionContainer {
        .option {
          font-family: 'NunitoSans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 23px;

          display: flex;
          align-items: center;
          letter-spacing: 0.0120588em;

          color: $primary-text-color;
          padding: 10px 14px;
          transition: background 0.1s ease-out;
          .checkbox {
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #D5D5DA;
            box-sizing: border-box;
            border-radius: 3px;
            width: 21px;
            height: 21px;
            margin-right: 16px;
            cursor: pointer;
            accent-color: $parent-primary-color;
            appearance: auto;
          }
          &:hover {
            background: #D5D5DA;
          }
        }
        .highlightOption {
          background: none;
        }
      }
    }
  }
}
