@import "../../theme/colors";

.checkbox-container {
  position: relative;
  padding: 10px 10px 10px 62px;
  cursor: pointer;
  user-select: none;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: $primary-text-color;
  min-height: 55px;
  background: #FFFFFF;
  border: 1px solid #D7D8E8;
  box-sizing: border-box;
  border-radius: 3px;
  width: 307px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover input ~ .checkmark {
    background-color: #fafafa;
  }

  input:checked ~ .checkmark {
    background-color: #006485;

    &:after {
      display: block;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 14px;
    left: 16px;
    height: 24px;
    width: 24px;
    background-color: #fafafa;
    border: 1px solid #006485;
    box-sizing: border-box;
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
