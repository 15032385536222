.unanswered-warning {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 13px;
    width: 100%;
}

.warning {
    width: 14px;
    height: 18px;
    margin-right: 16px;
}

.warning-surrogate {
    background-image: url("../../../resources/images/unansweredSurrogateWarning.svg");
}

.warning-parent {
    background-image: url("../../../resources/images/unansweredParentWarning.svg");
}

.unanswered-warning-text {
    font-family: "Nimbus Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    height: 22px;
    letter-spacing: 0.0120588em;
    color: #020202;
}
