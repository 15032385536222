.questionnaire-container {
  width: 100%;
  .question-block {
    position: relative;
    padding: 10px 0;
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    .fields-row {
      display: flex;
      gap: 20px;
    }
    .item-field {
      margin-bottom: 20px;
      .multiselect-dropdown-container {
        width: 250px;
      }
      .box-item {
        min-width: 250px;
        width: 284px;
      }
    }
  }
  .add-instance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
    max-width: 304px;
    background: #D5D5DA;
    border-radius: 3px;
    cursor: pointer;
    transition: opacity 0.1s ease-out;
    text-align: center;
    margin: 15px auto;
    img {
      margin-right: 9px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .remove {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
}