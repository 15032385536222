.modal-dialog {
    .link {
        font-size: 16px;
    }
    .close-confirmation-dialog {
        position: absolute;
        top: 26px;
        right: 26px;
        cursor: pointer;
    }

    .email-image {
        width: 100%;
        text-align: center;
    }

    h1 {
        font-weight: 800;
        font-size: 32px;
        line-height: 41px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.0120588em;
        color: #57575C;
        display: block;
        margin-top: 31px;
    }

    p {
        font-size: 16px;
        line-height: 26px;
        text-align: center;
        color: #525252;
        margin: 10px 5px;
    }

    button {
        background: #006485;
        border-radius: 36px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.0120588em;
        color: #FFFFFF;
        border: 0;
        width: 247px;
        height: 44px;
        text-align: center;
        margin: 0 auto;
        display: block;
        outline: none;
    }
}

@media (max-width: 500px) {
  .modal-dialog {
    .email-image {
      img {
        width: 200px;
      }
    }
    button {
      width: 150px;
      height: 38px;
    }
  }
}
