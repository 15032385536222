@import "../../theme/colors";

.app-survey-timeline {
  margin: 30px 35px;

  &.hidden {
    display: none;
  }

  &.finished {
    .app-survey-timeline-header {
      &:after {
        content: url("../../resources/images/complited.svg");
        top: 0;
        right: 10px;
        position: absolute;
      }
    }

    .survey-process {
      display: none;
    }
  }

  > {
    .app-survey-timeline-header {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 23px;
      text-transform: uppercase;
      display: flex;
      align-items: self-end;
      width: 245px;
      min-height: 25px;
      color: $secondary-text-color;
      position: relative;
    }

    ul {
      margin-top: 5px;
      position: relative;
      margin-left: 26px;

      > li::before {
        content: "";
        position: absolute;
        left: -23px;
        border-left: 2px solid #E5E5E5;
        height: calc(100% + 4px);
        width: 2px;
        top: 20px;
        padding-bottom: 2px;
      }

      &.enabled {
        > li {
          &:last-child::before {
            height: calc(100% - 4px);
          }

          &.active::before {
            left: -23px;
            top: 10px;
          }
        }

        > li.active::after {
          content: url(../../resources/images/list-bullet.svg);
          position: absolute;
          left: -29px;
          top: 16px;
        }
      }

      > li::after {
        content: url(../../resources/images/list-bullet.svg);
        position: absolute;
        left: -29px;
        top: 14px;
      }

      > li {
        &:last-child::before {
          content: "";
          position: absolute;
          width: 0;
          border-left: 0;
        }

        > .title {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.0120588em;
          color: $primary-text-color;
          margin-bottom: 1px;
          width: 176px;
          cursor: pointer;
          vertical-align: top;
          text-align: left;

          > span {
            float: right;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 23px;
            display: flex;
            align-items: center;
            letter-spacing: 0.0120588em;
            color: $secondary-text-color;
            width: 69px;
          }
        }
      }

      &.disabled > li > .title {
        color: $secondary-text-color;
      }

      > li {
        position: relative;
        width: 257px;
        display: flex;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        padding-left: 22px;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.selected > li.active {
        background: rgba(139, 189, 217, 0.1);
        border-radius: 10px 0 0 10px;
        color: #006485;
      }

      &.disabled > li.active > .title {
        color: #173432;
      }

      > li > .estimate {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.0120588em;
        color: $secondary-text-color;
        width: 176px;
      }

      &.enabled > li {
        &.active {
          background: rgba(139, 189, 217, 0.1);
          border-radius: 10px 0 0 10px;
          padding-top: 13px;
          padding-left: 22px;
          margin-left: -22px;
          margin-bottom: 7px;

          > {
            .title, .estimate {
              color: #006485;
            }
          }
        }

        &:first-child.active::before {
          top: 25px;
        }
      }
      .long-timeline-container {
        padding-left: 0;
        transform: translateX(-5px);
      }
    }
  }
}

@media (max-width: 1250px) {
  .survey-process {
    &:first-child {
      margin-top: 150px;
    }

    .back-button {
      display: none;
    }

    .app-survey-timeline {
      justify-content: center;
      width: 190px;
      margin: 20px 0 auto;

      > ul.selected > li.active {
        background: none;
      }

      .app-survey-timeline-header {
      }
    }
  }

  .app-survey-timeline {
    margin: 20px 0 auto;
    ul > li {
      width: 100%;
      padding: 10px 20px;
      border-radius: 15px !important;
    }
  }

  .start-application > h1 {
    text-align: center;
  }
}
