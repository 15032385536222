@import "../../theme/colors";

.empty-list-field {
  max-width: 100%;
  padding: 15px;
  text-align: center;

  img {
    width: 148px;
    height: 148px;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    color: $primary-text-color;
    font-weight: bold;
  }

  button {
    transition: 0.3s;
    padding: 10px 65px;
    font-size: 18px;
    border-radius: 25px;
    border: 1px solid $parent-primary-color;
    background: white;
    color: $parent-primary-color;
  }

  button:hover {
    transition: 0.3s;
    color: white;
    background: $parent-primary-color;
  }
}