.survey-question > {
    .help-text, .question-text {
        width: 100%;
        text-align: center;
    }

    .help-text {
        > span {
            text-decoration-line: underline;
        }

        margin-top: 14px;
        margin-bottom: 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.0120588em;
        color: #818181;
        cursor: pointer;
        position: relative;
        max-width: 500px;

        .hint {
            text-decoration-line: none!important;
            color: #818181;
            background: #FFFFFF;
            border: 1px solid #F0F0F0;
            box-sizing: border-box;
            border-radius: 2px;
            position: absolute;
            top: 18px;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 20px;
            z-index: 9999;
        }
    }
}

.hint-link {
  display: block;
  width: 100%;
  word-wrap: anywhere;
}

@media (max-width: 1250px) {
    .survey-questions > .survey-questions-container .survey-question > {
        .help-text, .question-text {
            width: 354px;
        }
    }
}
