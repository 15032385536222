@import "../../../theme/colors";

.questions-editor {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 25px;

  .questions-editor-title {
    font-size: 24px;
    font-weight: bold;
    color: $additional-text-color;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #EFEFEF;
  }

  .questions-editor-search {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .questions-search-field {
      display: flex;
      flex-direction: column;

      .search-label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
        color: $parent-second-color;
      }

      .search {
        max-width: 100%;
        padding: 10px 15px;
        border-radius: 15px;
        border: 1px solid $parent-second-color;
        font-size: 16px;
        color: $primary-text-color;
      }
    }
  }

  .salesforce-loader {
    width: 100%;
    position: relative;
    min-height: 150px;
    padding-top: 25px;
  }
}

@media (max-width: 1250px) {
  .questions-editor {
    padding: 60px 0;
  }

  .questions-editor-title {
    text-align: center;
  }

  .salesforce-no-data {
    padding: 10px;
    text-align: center;
  }
  .questions-search-field {
    padding: 10px;
  }
}
