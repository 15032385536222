.survey-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

input[type='file'] {
  display: none;
}

.next-section-button {
  background-color: #FFFFFF;
  width: 100%;
  text-align: center;
  padding-top: 12px;
  border: 1px solid #F0F0F0;
  margin-bottom: 12px;

  button {
    background-color: #A02C5E;
    border-radius: 36px;
    width: 307px;
    border: 0;
    margin-bottom: 16px;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.0120588em;
    color: #FFFFFF;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: bold;
  }
  .disabled {
    color: #979797;
    background-color: #EAEAEE;
  }
}

.answer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.unanswered-border {
  background: #FFFFFF;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 2px;
}

.unanswered-border-surrogate {
  border-color: #A02C5E!important;
}

.unanswered-border-parent {
  border-color: #816F4B!important;
}

.unanswered-border-egg_donor {
  border-color: #1a7b74!important;
}


.lite-wrapper {

  .survey-question.unanswered-border-surrogate {
    & > .question-answers {
      border: 1px solid #A02C5E;
      border-radius: 5px;
    }
  }

  .survey-question {
    & > .question-answers {
      border: 1px solid transparent;
    }
  }

  &.surrogate {
    .survey-question > .question-answers > button.selected {
      background: #F6F6F6;
      border: 1.5px solid #A02C5E;
      box-sizing: border-box;
      border-radius: 3px;
    }

    .checkmark {
      border: 1px solid #A02C5E!important;
    }

    input:checked ~ .checkmark {
      background-color: #A02C5E;
    }

    .radio-selected .checkmark {
      background-color: #A02C5E;
    }
  }

  .egg_donor {
    .survey-question > .question-answers > button.selected {
      background: #F6F6F6;
      border: 1.5px solid #397974;
      box-sizing: border-box;
      border-radius: 3px;
    }

    .checkmark {
      border: 1px solid #397974!important;
    }

    input:checked ~ .checkmark {
      background-color: #397974;
    }

    .radio-selected .checkmark {
      background-color: #397974;
    }
  }

  .parent {
    .survey-question > .question-answers > button.selected {
      background: #F6F6F6;
      border: 1.5px solid #816F4B;
      box-sizing: border-box;
      border-radius: 3px;
    }

    .checkmark {
      border: 1px solid #816F4B!important;
    }

    input:checked ~ .checkmark {
      background-color: #816F4B;
    }

    .radio-selected .checkmark {
      background-color: #816F4B;
    }
  }

  .left-column {
    width: 377px;
    order: 1;
    border-right: 1px solid #EFEFEF;
    height: calc(100vh - 80px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .progress-lite-survey-header {
    position: fixed;
    z-index: 9999;
    background-color: #FAFAFA;
    display: none;
  }
}

@media (max-width: 1250px) {
  .lite-wrapper {
    .progress-lite-survey-header {
      top: 0;
    }

  }
}

@media (max-width: 350px) {
  .survey-questions-container {
    width: 290px;
  }

  .survey-questions-container > .survey-question {
    width: 290px;

    > .question-answers > button {
      width: 270px;
    }
  }
}

