@import "../../../theme/colors";

.profile-information {
  margin-top: 33px;
  margin-left: 64px;
  .group {
    width: 100%;
    .group-title {
      margin-left: 19px;
      font-size: 18px;
      color: $primary-text-color;
    }

    .group-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 75px;
      border-bottom: 1px solid #E3E4E8;
      cursor: pointer;
      .accordion-wrapper {
        position: relative;
        padding: 30px 15px;
        .arrow {
          transform: rotate(180deg);
          transition: transform 0.2s ease-in-out;

          &.extended {
            transform: rotate(0deg);
          }
        }
        > svg {
          width: 20px;
          height: 20px;
          transition: transform 0.2s ease-in-out;
          margin-right: 20px;
        }
      }
    }

    .answers {
      margin-top: 0;
      padding-top: 0;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translateX(-30%);
      transition: max-height 0.3s ease-in-out, opacity 0.1s ease-in-out, padding 0.3s ease-in-out, transform 0.5s cubic-bezier(0.17, 0.67, 0.25, 1.02), background-color 1s ease-in-out;

      &.extended {
        opacity: 1;
        max-height: 99999px;
        transform: translateX(0);
        transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out, padding 0.3s ease-in-out 0.5s, transform 0.5s cubic-bezier(0.17, 0.67, 0.25, 1.02), background-color 1s ease-in-out;
        background-color: #f7f7f7;
      }

      .admin-answer-width {
        width: calc(100% - 35px) !important;
      }
      .dynamic-group {
        padding: 5px 15px;
        max-width: 100%;
        font-weight: 600;
        line-height: 30px;
        transition: 0.3s;
        border-top: 1px solid #f0f0f0;
        .dynamic-title {
          display: inline-block;
          width: 100%;
          font-size: 16px;
          color: $secondary-text-color;
        }
        .answer {
          font-size: 16px;
        }
      }
      .edit-phone-field {
        width: 50%;

        .react-tel-input .form-control {
          width: max-content;
          text-align: left;
        }

      }

      .answer-row-direction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;


        .answer-title {
          width: 50%;
          text-align: left;
          color: $secondary-text-color;
        }

        .edit-input {
          font-family: 'NunitoSans', sans-serif;
          font-style: normal;
          font-size: 15px;
          width: 50%;
          text-align: left;
          background: #F6F6F6;
          border: 1px solid #E9E9E9;
          border-radius: 4px;
          padding: 5px;
        }

        .answer-value {
          width: 50%;
          text-align: right;
          height: auto;
          color: $primary-text-color;
        }

        .yes-no-question {
          width: calc(100% - 65px);
          text-align: left;
          color: $secondary-text-color;
        }

        .yes-no-value {
          width: 50px;
          text-align: right;
          height: auto;
          color: $primary-text-color;
        }

      }

      .answer-border {
        border-top: 1px solid rgba(240, 240, 240, 1);
        padding: 5px 0;
      }

      .answer {
        transition: 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        line-height: 30px;
        max-width: 100%;
        padding: 5px 15px;

        .admin-icons {
          display: flex;
          width: 30px;
          margin-left: 10px;
          justify-content: flex-end;

          img {
            cursor: pointer;
          }

        }
      }

      .answer:hover {
        transition: 0.3s;
        background-color: #ededed;
      }
    }
  }
}
.group-content {
  padding: 15px 30px;

  .group-question {
    padding: 15px;
    background: white;
    border: 1px solid rgba(240, 240, 240, 1);
    border-radius: 5px;
    margin-bottom: 5px;

    .group-question-title {
      padding: 15px 5px 15px 5px;
      font-weight: bold;

      span {
        font-size: 18px;
        color: $primary-text-color;
      }
    }

    .answer {
      padding: 5px 5px !important;
    }
  }
}


@media (max-width: 1012px) {
  .profile-information {
    margin-left: 0;
    padding: 0 0 70px 0;

    .group {
      .answers {
        .answer-row-direction {
          flex-direction: column;
          justify-content: start;
          align-items: flex-start;
          .answer-title {
            width: 100% !important;
          }
          .answer-value {
            text-align: left;
            width: 100% !important;
          }
          .yes-no-question {
            width: 100%;
          }
          .yes-no-value {
            text-align: left;
            width: 100%;
          }
        }
      }
    }
  }
}
