.modalContainer {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow-y: auto;
  max-height: 600px;
  .title {
    font-family: "NunitoSans", sans-serif;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .cycleChangesContainer {
    margin-bottom: 20px;
  }

  .cycleTitle {
    font-family: "NunitoSans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0.0121em;
    text-align: left;

    color: #173A4E;
    margin-bottom: 10px;
  }

  .changeDetail {
    font-size: 16px;
  }

  .oldValue {
    color: #999;
  }

  .newValue {
    color: green;
  }
  p {
    text-align: left;
  }
  img {
    max-width: 300px;
  }
  .list-gallery > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > p {
      text-align: center;
    }
  }
}