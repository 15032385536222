@import "../../theme/colors";

.full-profile {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 50px 0 0 0;
  padding: 0 0 120px 0;

  .profile-top {
    display: flex;
    flex-direction: column;
    margin-left: 64px;

    .back {
      display: flex;
      align-items: center;
      width: 106px;
      height: 55px;
      margin: 0 0 0 15px;
      background: url(../../resources/images/back.svg) no-repeat 0 center;
      cursor: pointer;

      span {
        margin: 0 0 0 45px;
        color: $additional-text-color;
        font-weight: bold;
      }
    }

    .profile-menu-wrapper {
      display: flex;
      margin: 30px 0 0 0;

      .profile-image-wrapper {
        width: 147px;
        height: 147px;
        background-color: #717485;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 5px;
        }
      }

      .donor-menu {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        margin: 0 0 0 21px;

        .donor-number {
          display: flex;
          align-items: center;
          gap: 15px;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          letter-spacing: 0.0120588em;
          margin-bottom: 10px;
          color: $primary-text-color;
        }
        .donor-short-info, .donor-email {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.0120588em;
          margin-bottom: 5px;
          color: #020202;
        }

        .donor-sfdc {
          display: flex;
          align-items: center;
          gap: 5px;
          font-style: normal;
          font-size: 16px;
          line-height: 28px;
          color: #020202;
          cursor: pointer;
          border: 1px #E9E9E9 solid;
          border-radius: 4px;
          background-color: #F6F6F6;
          padding: 8px;
          transition: 0.3s;
          &:hover {
            background-color: #ededed;
          }
          > img {
            width: 16px;
            height: 16px;
            color: #0cbf0c;
          }
        }
        .donor-email {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
            opacity: 0.8;
          }
        }

        .actions {
          display: flex;
          align-items: center;
          margin: 12px 0 0 0;

          .favorite-donor {
            display: flex;
            align-items: center;
            width: 124px;
            height: 42px;
            cursor: pointer;
            padding-left: 45px;
            border: 1px solid $parent-primary-color;
            box-sizing: border-box;
            border-radius: 42px;
            color: $parent-primary-color;
            margin: 0 14px 0 0;
            background: url(../../resources/images/brown-heart.svg) no-repeat 12px center #fff;
            transition: border, color, background-color 0.3s ease-out;
            &:hover {
              border: 1px solid $parent-primary-color;
              background: url(../../resources/images/heart.svg) no-repeat 12px center $parent-primary-color;
              color: white;
            }
          }
          .favorite-donor-liked {
            background: url(../../resources/images/heart-active.svg) no-repeat center;
            width: 20px;
            height: 18.35px;
            margin: 0 20px 0 0;
            animation: pulse 1.5s ease-in-out infinite;
            cursor: pointer;
          }

          .reserve-donor {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 176px;
            height: 44px;
            cursor: pointer;
            border: 1px solid $parent-primary-color;
            box-sizing: border-box;
            border-radius: 36px;
            color: white;
            background-color: $parent-primary-color;
            user-select: none;
          }
          .compensation-container {
            display: flex;
            align-items: flex-end;
            gap: 18px;
            min-width: 100%;
            .compensation-content {
              flex-grow: 2;
              max-width: 300px;
              .title {
                margin-bottom: 10px;
                color: #020202;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
              }
              > input {
                font-family: "NunitoSans", sans-serif;
                font-size: 15px;
                line-height: 22px;
                font-weight: 400;
                padding: 3px 6px;
                height: 48px;
                border-radius: 4px;
                border: 1px $border-grey-color solid;
                background-color: #F6F6F6;
                width: 97%;
              }
            }
            .save-compensation-btn {
              font-family: "NunitoSans", sans-serif;
              position: relative;
              padding: 7px 10px;
              border-radius: 42px;
              width: 111px;
              height: 42px;
              background-color: transparent;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $parent-primary-color;
              border: 1px #816F4B solid;
            }

            .checkmark {
              position: absolute;
              left: 15%;
              opacity: 0;
              transition: all 0.3s ease-in-out;
            }

            .checkmark.show {
              opacity: 1;
            }

            .button-text {
              font-size: 16px;
              font-weight: 400;
              line-height: 28px;
              transition: all 0.3s ease-in-out;
            }

            .save-compensation-btn .show + .button-text {
              padding-left: 25px;
            }
          }
        }
        .pdf-wrapper {
          transition: 0.3s;
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: $parent-primary-color;
          margin-top: 25px;
          width: 100%;
          max-width: 150px;
          border: none;

          .print-pdf {
            background: url(../../resources/images/print.svg) no-repeat 0 center;
            span {
              padding-left: 30px;
              font-size: 16px;
              line-height: 28px;
              color: $parent-primary-color;
            }
          }

          &:hover {
            transition: 0.3s;
            .print-pdf {
              span {
                color: $parent-second-color;
              }
            }
          }
        }
      }
    }
  }
}

.tabs.desktop-version {
  .tab-list {
    margin-top: 33px;
    margin-left: 64px;
    margin-bottom: 28px;
    width: 90%;
  }
}

.tabs.mobile-version {
  display: none;
}

.reserve-donor-popup,
.congratulations-popup {
  .content {
    padding: 5px 50px 25px 50px;
  }
  font-family: NunitoSans, sans-serif;

  .title {
    color: $additional-text-color;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 41px;
    letter-spacing: 0.0121em;
    text-align: center;
    margin-bottom: 40px;
  }

  .information-block {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0;
    text-align: center;
    color: $primary-text-color;
    .calendly-button {
      margin: 0 5px;
    }
  }

  .buttons-wrapper {
    position: relative;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .yes,
    .no {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 11px 28px;
      border-radius: 36px;
      cursor: pointer;
      user-select: none;
      line-height: 20px;
      transition: color, background-color 0.1s ease-out;

      &.disabled {
        visibility: hidden;
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .no:hover {
      background-color: $conab-danger-color;
      color: #fff;
    }

    .yes {
      border: 1px solid $parent-primary-color;
      background: $parent-primary-color;
      color: #FFF;
      width: 110px;

      .spinner {
        width: 20px;
        height: 20px;
        fill: $primary-text-color;
        margin-right: 10px;
      }
    }

    .no {
      border: 1px solid $conab-danger-color;
      color: $conab-danger-color;
      margin-left: 20px;
      width: 110px;
    }
  }
}

.schedule-popup {
  font-family: NunitoSans, sans-serif;

  .content {
    padding: 15px;

    .title {
      color: $additional-text-color;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 41px;
      letter-spacing: 0.0121em;
      text-align: center;
      margin-bottom: 15px;
    }

    .information-block {
      .donor-reserve-info-main {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
      }

      .donor-reserve-info-sub {
        font-size: 16px;
        font-weight: 600;
        color: $secondary-text-color;
      }

      a {
        transition: 0.3s;
        display: block;
        padding: 15px 0;
        background: $parent-primary-color;
        max-width: 200px;
        color: white;
        border-radius: 25px;
        text-decoration: none;
        font-weight: bold;
        margin: 25px auto 0;
      }

      a:hover {
        transition: 0.3s;
        background-color: $parent-primary-color;
      }
    }
  }
}

@media (max-width: 1024px) {
  .tabs.desktop-version {
    display: none;
  }
  .favorite-donor {
    width: 100% !important;
    max-width: 120px !important;
    height: 34px !important;
  }

  .pdf-wrapper, .reserve-donor {
    width: 100% !important;
    margin: 10px 0 0 0 !important;
    max-width: 150px !important;
    height: 34px !important;
    padding: 0 10px;
  }

  .pdf-wrapper {
    span {
      font-size: 14px !important;
    }
  }

  .full-profile {
    min-width: max-content;
    .profile-top {
      margin-left: 5px;

      .profile-menu-wrapper {
        .donor-menu {
          .donor-number {
            font-size: 20px;
          }
          .actions {
            display: block;
            margin: 5px 0 0 0;

            .reserve-donor {
              margin: 15px 0 0 0;
              width: 155px;
            }
          }
        }
      }
    }

    .profile-information {
      &.full-version {
        display: none;
      }
    }
  }

  .photo-list-wrapper {
    &.full-version {
      display: none;
    }
  }

  .tabs.mobile-version {
    width: 100%;
    display: block;
    .group {
      max-width: 100vw;
      .answers {
        .answer {
          .answer-title {
            width: 25%;
            margin-right: 5px;
          }
          .answer-value,
          .edit-input {
            width: 50%;
            color: $primary-text-color;
          }
          .admin-icons {
            margin: 0;
            padding-left: 5px ;
          }
        }
      }
    }
  }
  .schedule-popup {
    width: 100%;
    max-width: 450px;
  }
}

@media (max-width: 500px) {
  .reserve-donor-popup {
    width: calc(100% - 50px);

    .header {
      padding: 8px 8px 0 8px;
    }

    .content {
      padding: 5px 5px 16px;

      .title {
        font-size: 26px;
        margin-bottom: 25px;
      }
      .buttons-wrapper {
        width: 100%;
      }
    }
  }
  .congratulations-popup {
    width: calc(100% - 50px);
    .content {
      padding: 5px 5px 25px 5px;
      .title {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }
  }
  .tabs.mobile-version {
    .group {
      .answers {
        .answer {
          justify-content: flex-start;
          margin-right: 10px;
          .admin-icons {
            flex-grow: 1;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .schedule-popup {
    width: 100%;
    max-width: 300px;
  }
}
.full-profile-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
}


@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}