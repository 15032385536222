.start-application > {
    .profile-info {
        display: none;
    }

    h1 {
        margin-top: 49px;
    }

    .profile-info {
        &:first-child {
            text-align: center;
            padding-bottom: 28px;
            margin-top: 62px;
        }

        > {
            div {
                text-align: center;

                &:last-child {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0.0120588em;
                    color: #173432;
                    margin-top: 7px;
                }
            }

            .profile-image {
                width: 71px;
                height: 71px;
                background: #CFCFCF;
                background-blend-mode: normal;
                border: 1px solid #FFFFFF;
                border-radius: 35px;
                font-weight: 300;
                font-size: 30px;
                line-height: 41px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 0.0120588em;
                color: #57575C;
                position: relative;
                margin: 0 auto;
            }
        }
    }

    div > .profile-image > .add-image {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 11px;
    }
}

.desktop-start {
    display: inline;
}

.mobile-button {
    display: none;
}

@media (max-width: 1250px) {
    .start-application > {
        .profile-info {
            display: block;
        }

        h1 {
            margin-top: 0!important;
        }
    }

    .survey-start .app-survey-timeline {
        margin-top: 0;
    }
}
