@import "../../theme/colors";

.dynamic-group-section {
  padding: 26px 58px;
  width: 431px;
  background: #FFFFFF!important;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  .dynamic-instance {
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    padding: 18px 24px;
    margin-bottom: 30px;
    .instance-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.0120588em;

      color: $primary-text-color;
      > img {
        cursor: pointer;
      }
      .remove-icon {
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: 0.1s ease-in-out;
        &:hover {
          opacity: 0.8;
        }
      }

    }
    .survey-questions-container {
      width: max-content;
      .survey-question {
        width: 302px !important;
        border: none;
        margin-bottom: 0;
        .question-text {
          font-family: 'NunitoSans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          padding: 10px 0;
          text-align: left;
          color: $primary-text-color;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
        .help-text {
          font-size: 15px;
          line-height: 15px;
          margin-top: 7px;
          margin-bottom: 15px;
        }
      }
    }
  }

  > div:first-child {
    margin-bottom: 20px;
  }

  .question-title {
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.0120588em;
    color: $primary-text-color;
    box-sizing: border-box;
  }

  .add-new-question {
    margin-top: 16px;
    width: 171px!important;
  }

  .survey-section {
    width: 100%;
    position: relative;
  }

  .dynamic-group {
    margin-top: 16px;
    width: 171px;
  }
  .content {
    .group-question-container {
      margin-top: 0 !important;
      top: 0 !important;
      padding-bottom: 0;
    }

    .add-more {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 42px;
      max-width: 304px;
      background: #F6F6F6;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 auto;
      transition: background-color 0.1s ease-out;
      img {
        margin-right: 9px;
      }
      &:hover {
        background: #D5D5DA;
      }
    }
  }
}

@media (max-width: 1250px) {
  .dynamic-group-section {
    padding: 20px;
    .dynamic-instance {
      .survey-questions-container {
        .survey-question {
          width: 290px !important;
          .question-text {
            padding: 8px 29px;
          }
        }
      }
    }
  }
}