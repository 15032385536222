@import "../../../theme/colors";

.admin-main {
    position: relative;
    box-sizing: content-box;
    width: 100%;
    max-width: 1100px;
    align-self: flex-start;
    padding-bottom: 80px;
  .admin-survey-main {
    .summary-container {
      padding: 48px 99px;
    }
  }
  .survey-questions-container {
    top: 50px;
  }
}

.sticky-save-button-container {
  position: fixed;
  top: 130px;
  right: 90px;
  z-index: 1000;
  .save-changes {
    font-family: "NunitoSans", sans-serif;
    position: relative;
    padding: 7px 10px;
    border-radius: 42px;
    width: 143px;
    height: 42px;
    background-color: transparent;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $parent-primary-color;
    border: 1px #816F4B solid;
    &.disabled {
      color: #979797;
      background-color: #EAEAEE;
      border: none;
    }
  }

  .checkmark {
    position: absolute;
    left: 15%;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .checkmark.show {
    opacity: 1;
  }

  .button-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    transition: all 0.3s ease-in-out;
  }

  .save-changes .show + .button-text {
    padding-left: 25px;
  }
}

@keyframes shake {
  0%, 90%, 100% {
    transform: translateX(0);
  }
  92.5% {
    transform: translateX(-5px);
  }
  97.5% {
    transform: translateX(5px);
  }
}

.save-changes.shake {
  animation: shake 5s ease-in-out infinite;
}