.question-statuses {
  position: relative;

  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #A8D5EB;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
  }

  .progress-bar__fill {
    height: 100%;
    background-color: #FFFFFF;
    transition: width 0.3s ease-in-out;
  }

  .progress-bar__label {
    position: absolute;
    left: 2px;
    top: 10px;
    color: #f3f3f3;
    font-size: 12px;
  }
}

@media (max-width: 1250px) {
  .question-statuses {
      .progress-bar__label {
        top: 7px;
        left: 45px;
      }
  }
}
