@import "../../../theme/colors";

.notes-container {
  padding: 0 5px;
  margin-left: 64px;
  .notes-list {
    margin-bottom: 10px;
  }

  .create-note-container {
    position: relative;
    margin-bottom: 28px;
    .note-input {
      box-sizing: border-box;
      font-family: "NunitoSans", sans-serif;
      font-size: 15px;
      line-height: 22px;
      font-weight: 400;
      padding: 8px 12px 60px;
      width: 99%;
      resize: none;
      height: 98px;
      transition: all 0.3s ease;
      margin-top: 10px;
      background-color: #F6F6F6;
      color: #8C8C8C;
      border-radius: 8px;
      border: 1px solid $border-grey-color;

      &:focus {
        outline: none;
        border-color: $border-grey-color;
      }

      &:hover {
        border-color: #959595;
      }
    }
    .create-note-btn {
      position: absolute;
      right: 10px;
      bottom: 8%;
      width: 85px;
      height: 36px;
      padding: 7px 16px 7px 16px;
      border-radius: 30px;
      border: none;
      background-color: #1890FF;
      color: #FFFFFF;
    }
  }
}
