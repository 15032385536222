.full-page {
  position: relative;

  .prev-profile,
  .next-profile {
    position: absolute;
    top: 357px;
    cursor: pointer;
    max-width: 55px;
    height: 137px;

    .oval {
      width: 100%;
      height: 100%;
    }
    .empty-oval {
      width: 24px;
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .prev-profile {
    left: -1px;

    .arrow {
      left: 7px;
    }
  }

  .next-profile {
    right: -1px;

    .arrow {
      right: 7px;
    }
  }

  @media (max-width: 1024px) {
    .prev-profile,
    .next-profile {
      max-width: 30px;
      height: 80px;
      top: 280px;
      .empty-oval {
        width: 14px;
        height: 80px;
      }
      .arrow {
        width: 9px;
        height: 20px;
      }
    }

    .prev-profile {
      .arrow {
        left: 5px;
      }
    }

    .next-profile {
      .arrow {
        right: 5px;
        transform: translateY(-50%);
      }
    }
  }
}

.prev-profile:hover, .next-profile:hover {
  filter: brightness(1.1);
  transform: scale(1.05);
  transition: transform 0.3s ease, filter 0.3s ease;
}

.prev-profile:active, .next-profile:active {
  filter: brightness(0.9);
  transform: scale(0.95);
}

@keyframes swipeRight {
  0% { transform: translateX(0); }
  100% { transform: translateX(20px); }
}

@keyframes swipeLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-20px); }
}

.animate-swipe-right {
  animation: swipeRight 0.5s ease forwards;
}

.animate-swipe-left {
  animation: swipeLeft 0.5s ease forwards;
}
