@import "../../theme/colors";

.survey-header-container {
    .survey-questions-header {
        width: 575px;
        height: 58px;
        background: $survey-info-bg;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        margin-top: 15px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #FFFFFF;
        box-sizing: border-box;
        margin-bottom: 28px;

        &.survey-questions-header-mobile {
            display: none;
        }

        > {
            .title {
                padding-left: 25px;
                width: 132px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                height: 23px;
                line-height: 23px;
                background: none;
                color: #FFFFFF;
            }

            .estimate {
                white-space: nowrap;
            }

            div > img {
                margin-left: 40px;
                margin-right: 8px;
            }
        }

        &:first-child {
            padding-right: 25px;
        }

        > .question-statuses {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            width: 363px;
            margin-left: 30px;

            > {
                .question-status {
                    background: $survey-info-question-empty;
                    border-radius: 11px;
                    height: 4px;
                    margin-right: 4px;
                    display: inline-block;
                    flex: 1;
                }

                .question-status-answered {
                    background: $survey-info-question-answered;
                }
            }
        }
    }
}

.parent-survey-header {
    background-color: $survey-info-parent-bg !important;
    .question-status {
        background-color: $survey-info-parent-question-empty !important;
    }

    .question-status-answered {
        background-color: $survey-info-parent-question-answered !important;
    }
}

@media (max-width: 1250px) {
    .survey-header-container {
        top: 34px;
        left: 0;
        z-index: 10;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        box-sizing: border-box;


        .survey-questions-header {
            width: 100%;
            height: 58px;

            &.survey-questions-header-desktop {
                display: none;
            }

            &.survey-questions-header-mobile {
                display: flex;
                flex-direction: column;
                position: relative;

                > div:first-child {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: 3px;

                    > {
                        .title {
                            order: 0;
                            padding-left: 56px;
                        }

                        .time {
                            > span > img {
                                padding-right: 8px;
                            }

                            display: flex;
                            align-items: center;
                            flex: 1;
                            justify-content: flex-end;
                            padding-right: 13px;
                        }
                    }
                }

                .back-button {
                    margin: 0;
                    position: absolute;
                    left: 14px;
                    z-index: 9999;
                    top: 21px;
                    width: 26px;
                    height: 14px;
                }
            }

            .question-statuses {
                position: relative;
                top: -9px;
                width: 100%;
                box-sizing: border-box;
                padding-left: 42px;
                padding-right: 22px;
            }
          &.survey-questions-header-mobile.hidden-back-btn {
            div:first-child {
              .title, .question-statuses {
                padding-left: 20px;
              }
            }
            .question-statuses {
              padding-left: 10px;
            }
          }
        }
    }
}
