@import "../../theme/colors";

@media (max-width: 1250px) {
    .survey-questions > .survey-header-container > .survey-questions-header-mobile > div:first-child > .title {
        max-width: calc(100% - 180px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.mobile-survey-process {
    position: fixed;
    z-index: 20000;
    background: white;
    bottom: 0;
    left: 0;
    top: 0;
    width: calc(100% / 3);
    min-width: 275px;
    transform: translateX(-100%);

    h2 {
        padding: 15px;
        font-size: 20px;
        font-weight: bold;
        color: white;
    }

    .sections-and-subsurveys {
        height: calc(100% - 95px);
        overflow-y: auto;
        overflow-x: hidden;

        .app-survey-timeline {
            margin: 0;
            padding: 15px;

            .app-survey-timeline-header {
                width: 100%;
            }

            .app-survey-timeline-header:after {
                right: 0;
            }
        }
    }
}

.show-mobile-sections {
    box-shadow: 1px 0 12px 5px rgba(0, 0, 0, 0.2);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.hide-mobile-sections {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}
.show-sections {
    position: fixed;
    left: 0;
    bottom: 65px;
    width: 45px;
    height: 45px;
    border: none;
    color: white;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    img {
      height: 36px;
      width: 36px;
    }
}

.hide-sections {
    position: absolute;
    bottom: 0;
    width: 100%;
    border: none;
    height: 45px;
    color: white;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 36px;
      width: 36px;
    }
}

@keyframes slide-in {
    0% {
        -webkit-transform: translateX(-100%);
    }
    100% {
        -webkit-transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }
    100% {
        -webkit-transform: translateX(-100%);
    }
}

.ip-colors {
    background-color: $parent-app-color;
}

.ed-colors {
    background-color: $egg-donor-app-second-color;
}

.gc-colors {
    background-color: $surrogate-app-color;
}