@import "../../theme/colors";

.group-section {
    background: #F7F7F7!important;
    border-radius: 4px;
    padding: 26px 58px;
    width: 431px;

    .survey-question {
        width: 431px!important;
    }

    > div:first-child {
        padding-bottom: 20px;
    }

    .question-title {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: $primary-text-color;
        line-height: 16px;
        letter-spacing: 0.0120588em;
        display: flex;
        align-items: center;
        width: 100%;
        position: sticky;
        top: 100px;
        z-index: 9;
        padding-top: 10px;
        background: #F7F7F7!important;
    }

    .add-new-question {
        margin-top: 16px;
        width: 171px!important;
    }

    .survey-section {
        width: 100%;
        position: relative;
    }

    .group-section {
        margin-top: 16px;
        width: 171px;
    }
    .content {
        .group-question-container {
          margin-top: 0 !important;
          top: 0 !important;
          padding-bottom: 0;
        }
    }
}

@media (max-width: 1250px) {
    .group-section .survey-questions-container .survey-question {
      width: 320px!important;
    }
    .group-section {
      .question-title {
        top: 130px;
        width: 320px!important;
      }
    }
}