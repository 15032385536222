@import "../../theme/colors";

.radio-container {
    position: relative;
    padding: 10px 10px 10px 62px;
    cursor: pointer;
    user-select: none;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    color: $primary-text-color;
    min-height: 55px;
    background: #FFFFFF;
    border: 1px solid #D7D8E8;
    box-sizing: border-box;
    border-radius: 3px;
    width: 307px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 15px;
        left: 16px;
        height: 24px;
        width: 24px;
        background-color: #FFFFFF;
        border: 1px solid #006485;
        border-radius: 50%;

        &:after {
            content: '';
            position: absolute;
            display: none;
            top: 8px;
            left: 8px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }

    }
}

.radio-selected .checkmark {
  background-color: #006485;

  &:after {
    display: block;
  }
  &:hover input ~ .checkmark {
    background-color: #fafafa;
  }
}