.simple-spinner {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  span {
    display: block;
    width: 25px;
    height: 25px;
    border: 3px solid #816F4B;
    border-radius: 50%;
    border-right-color: rgba(255, 255, 255, 0.7);
    animation: spinner-anim 1s linear infinite;
  }
}

.survey-loader  {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 200px;
  transform: translateX(-50%);
  span {
    width: 50px;
    height: 50px;
    border: 6px solid #816F4B;
    border-radius: 50%;
    border-right-color: rgba(255, 255, 255, 0.7);
    animation: spinner-anim 1s linear infinite;
  }
}


@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}