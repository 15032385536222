.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;;
  > section {
    position: relative;
    flex: 1;
    color: #000;

    > .columns {
      display: flex;
      flex: 1;
      > {
        .center-column {
          flex: 1;
          order: 3;
          height: calc(100vh - 140px);
          justify-content: center;
          z-index: 2;
          overflow-x: hidden;
          overflow-y: auto;
          padding-bottom: 60px;
          .content-container {
            display: flex;
            justify-content: center;
            flex: 1;
            order: 3;
            &.infinite {
              overflow-y: hidden;
            }
            &.full-page {
              justify-content: space-between;
              flex-wrap: wrap;
            }
            .right-column {
              width: 285px;
              order: 3;
              height: calc(100vh - 140px);
            }
          }
        }

        .center-column-lg {
          height: calc(100vh - 80px);
        }

        .left-column {
          width: 285px;
          order: 1;
          height: calc(100vh - 140px);
          overflow-x: hidden;
          overflow-y: auto;
          border-right: 1px solid #EFEFEF;
        }
      }
      footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}


@media (max-width: 1250px) {
  body {
    overflow: auto;
    position: -webkit-sticky;
    display: block;
  }

  .wrapper > section > .columns {
    position: relative;
    height: auto;
    flex-direction: column;
    > {
      .left-column {
        width: 100%;
        height: auto;
        order: 2;
        border-bottom: 0;
        display: flex;
        justify-content: center;
      }
      .center-column {
        height: auto;
        overflow: unset;
        padding-bottom: 0;
        .content-container {
          height: auto;
          position: static;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .right-column {
            display: none;
          }
        }
      }
    }
    footer {
      bottom: 0;
    }
  }
}


@media (max-width: 1012px) {
  .wrapper > section > .columns {
    > {
      .center-column {
        .content-container {
          &.full-page {
            flex-direction: column;
            align-items: center;
          }
          .right-column {
            display: none;
          }
        }
      }
    }
  }
}