.reserved-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    letter-spacing: 0.0120588em;
    color: #3E4151;
    margin-bottom: 18px;
  }

  .text {
    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    max-width: 250px;

    letter-spacing: 0.0120588em;
    color: #22232D;
    margin-bottom: 45px;
  }

  .back-home {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 42px;
    cursor: pointer;
    padding: 0 45px;
    border: 1px solid #816F4B;
    box-sizing: border-box;
    border-radius: 42px;
    color: #816F4B;
    user-select: none;
    transition: color, background-color 0.2s ease-out;

    &:hover {
      color: #FFFFFF;
      background: #816F4B;
    }
  }
}

@media (max-width: 1250px) {
  .reserved-banner .text {
    text-align: center;
    margin-bottom: 25px;
  }
}