@import "../../theme/colors";

.back-button {
    width: 173px;
    margin: 68px auto 68px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $additional-text-color;

    > img {
        margin-right: 21px;
    }
}

@media (max-width: 1250px) {
    .back-button {
        margin: 68px auto 0;
        height: 43px;
        display: flex;
        justify-content: left;
        cursor: pointer;
        width: 450px;
        position: relative;
        top: -10px;
    }
}
