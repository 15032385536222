.admin-pdf-redirect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 24px;

  .app-logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 176px;
    height: 43px;
    cursor: pointer;
  }

  .loading-pdf-message, .pdf-ready-message {
    text-align: center;

    h2 {
      font-size: 24px;
      color: #173A4E;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      color: #555;
      max-width: 500px;
    }

    button {
      margin-top: 30px;
      background-color: rgba(129, 111, 75, 1);
      color: #ffffff;
      border: none;
      padding: 10px 20px;
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: rgb(105, 89, 61);
      }
    }
  }
}
