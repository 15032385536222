@import "../../theme/colors";

.survey-questions {
  margin: 0 auto;
  .survey-questions-container {
    .survey-question {
      > {
        .question-answers {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .question-text {
          width: 100%;
          text-align: center;
          font-weight: 600;
          font-size: 21px;
          line-height: 30px;
          letter-spacing: 0.0120588em;
          color: $primary-text-color;
          box-sizing: border-box;
          padding: 30px;
        }

        .question-answers > button {
          background: #F6F6F6;
          border: 1.5px solid #F6F6F6;
          box-sizing: border-box;
          border-radius: 3px;
          width: 307px;
          margin-bottom: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.0120588em;
          color: $primary-text-color;
          padding-top: 6px;
          padding-bottom: 6px;
          transition: background-color 0.1s ease-out;
          &:hover {
            background: #D5D5DA;
          }

          &.selected {
            background: #F6F6F6;
            border: 1.5px solid #006485;
            box-sizing: border-box;
            border-radius: 3px;
          }
        }
      }

      width: 575px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      margin-bottom: 16px;
      position: relative;
    }

    width: 575px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    position: relative;
    margin-top: 121px;
    padding-bottom: 70px;
  }

  .survey-questions-container-egg_donor .survey-question > .question-answers > button.selected {
    background: #F6F6F6;
    border: 1.5px solid $egg-donor-app-color;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .survey-questions-container-parent .survey-question > .question-answers > button.selected {
    background: #F6F6F6;
    border: 1.5px solid $parent-primary-color;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .survey-questions-container-surrogate .survey-question > .question-answers > button.selected {
    background: #F6F6F6;
    border: 1.5px solid $surrogate-app-second-color;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .progress-survey-header {
    position: fixed;
    z-index: 9999;
    background-color: #FAFAFA;
    top: 80px;
  }
}

/** ANSWERS */

.survey-questions-container-egg_donor .radio-selected .checkmark {
  background-color: $egg-donor-app-color;
}

.survey-questions-container-parent .radio-selected .checkmark {
  background-color: $parent-primary-color;
}

.survey-questions-container-surrogate .radio-selected .checkmark {
  background-color: $surrogate-app-color;
}



.survey-questions-container-egg_donor .checkmark {
  border: 1px solid $egg-donor-app-color !important;
}

.survey-questions-container-parent .checkmark {
  border: 1px solid $parent-primary-color !important;
}

.survey-questions-container-surrogate .checkmark {
  border: 1px solid $surrogate-app-color !important;
}


.survey-questions-container-surrogate input:checked ~ .checkmark {
  background-color: $surrogate-app-color;
}

.survey-questions-container-parent input:checked ~ .checkmark {
  background-color: $parent-primary-color;
}

.survey-questions-container-egg_donor {
  input:checked ~ .checkmark {
    background-color: $egg-donor-app-color;
  }

  .input:checked ~ .checkmark {
    background-color: #816F4B!important;
  }
}

.survey-question {
  input[type='text'] {
    color: $primary-text-color;
    background: #F6F6F6;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 4px;
    height: 55px;
    width: 307px;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0120588em;
    margin-bottom: 20px;
  }

  .masked-input-label {
    width: 307px;
    padding-bottom: 10px;
  }

  input[type='text'].masked {
    background: #F6F6F6;
    border: 1px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 4px;
    height: 55px;
    width: 307px;
    padding: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
  .note-text {
    padding-bottom: 30px;
  }
}

.survey-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

input[type='file'] {
  display: none;
}

@media (max-width: 1250px) {
  .survey-questions > .survey-questions-container {
    .survey-question,
    .next-section-button {
      width: 354px;

      > .question-text {
        width: 354px;
      }
    }
  }

  .progress-survey-header {
    &.survey-header-container {
      top: 34px;
      left: 0;
      z-index: 10;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 350px) {
  .survey-questions-container,
  .next-section-button {
    width: 290px;
  }

  .survey-questions > .survey-questions-container > .survey-question {
    width: 290px;

    > .question-answers > button {
      width: 270px;
    }
  }
}

.next-section-button {
  background-color: #FFFFFF;
  width: 100%;
  text-align: center;
  padding-top: 12px;
  border: 1px solid #F0F0F0;
  margin-bottom: 12px;

  button {
    background: #F6F6F6;
    border-radius: 36px;
    width: 307px;
    border: 0;
    margin-bottom: 16px;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.0120588em;
    color: #FFFFFF;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: bold;
  }
}

.answer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.unanswered-border {
  background: #FFFFFF;
  border: 1px solid;
  box-sizing: border-box;
  border-radius: 2px;
}

.unanswered-border-surrogate {
  border-color: $surrogate-app-color !important;
}

.unanswered-border-parent {
  border-color: $parent-primary-color !important;
}

.unanswered-border-egg_donor {
  border-color: $egg-donor-app-color !important;
}