@import "../../theme/colors";

.profile-container {
  margin: 0 auto;
  .profile-form {
    margin: 0 auto;
    text-align: center;

    > {
      div {
        &:first-child {
          margin-top: 53px;
        }

        > .profile-image {
          width: 71px;
          height: 71px;
          background: #CFCFCF;
          background-blend-mode: normal;
          border: 1px solid #FFFFFF;
          border-radius: 35px;
          font-weight: 300;
          font-size: 30px;
          line-height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.0120588em;
          color: #57575C;
          position: relative;
          margin: 0 auto;
          cursor: pointer;

          > .add-image {
            position: absolute;
            right: 0;
            bottom: 0;
            background: #ffffff;
            border: 1px solid #ffffff;
            border-radius: 11px;
          }
        }
      }

      .form {
        width: 450px;
        margin: 29px auto;
        text-align: left;

        .form-item {
          margin-bottom: 31px;
          position: relative;

          label {
            font-size: 15px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.0120588em;
            color: $primary-text-color;
          }

          input {
            height: 55px;
            background: #F6F6F6;
            border: 1px solid #E9E9E9;
            box-sizing: border-box;
            border-radius: 4px;
            width: 100%;
            margin-top: 9px;
            padding: 16px 40px 16px 16px;
            font-size: 15px;
          }
        }

        .save-button {
          background: $conab-primary-color;
          border-radius: 36px;
          width: 215px;
          height: 44px;
          font-size: 14px;
          letter-spacing: 0.0120588em;
          text-align: center;
          outline: none;
          border: 0;
          margin-top: 15px;
          color: #FFFFFF;
        }

        .disabled {
          background: #EAEAEE;
          color: $secondary-text-color;
        }

        .sign-in {
          font-size: 14px;
          line-height: 28px;
          display: flex;
          align-items: center;
          letter-spacing: 0.0120588em;
          color: $secondary-text-color;
          margin-top: 29px;
        }
      }

      .sign-in {
        label {
          width: 176px;
        }

        a {
          text-decoration-line: underline;
          color: $conab-primary-color;
        }
      }

      .form > {
        .change-password {
          margin-top: 41px;
          margin-left: 24px;
          margin-bottom: 44px;

          > div {
            text-decoration: none;
            color: $conab-primary-color;
            cursor: pointer;
            width: max-content;
          }
        }

        .delete-account {
          margin-top: 60px;
          margin-left: 24px;

          > div {
            color: $conab-danger-color;
            text-decoration: none;
            cursor: pointer;
            width: max-content;
          }
        }
      }
    }

    .fileUploader {
      display: none;
    }
  }
}

@media (max-width: 1250px) {
    .profile-container {
      margin: 0;
      .profile-form {
        width: 333px;

        > div:first-child {
          margin-top: 19px;
        }
        .form {
          width: 333px;
        }
      }
    }

      .wrapper > section > .profile-page > {
        .center-column {
          box-sizing: border-box;
          margin: 0;
        }

        .left-column {
          width: 100%;
          height: auto;
          order: 1;
          border-bottom: 1px solid #EFEFEF;
          box-sizing: border-box;
          padding-left: 34px;
        }
      }
}

@media (max-width: 350px) {
    .profile-container .profile-form .form {
        width: 290px;
    }
}
