nav.mobile-menu > nav.mobile-dropdown-menu {
    background-color: #fff!important;
    border-bottom: 1px solid #eaeaee;
    border-left: 1px solid #eaeaee;
    border-top: 1px solid #eaeaee;
    height: calc(100vh - 42px);
    opacity: .96;
    overflow-y: auto;
    position: absolute;
    right: 0;
    max-width: 300px;
    width: -moz-available;
    top: 41px;
    z-index: 0;
    box-sizing: border-box;

    > section {
        padding: 0 2rem;
        box-sizing: border-box;
        position: relative;
        top: 0;

        &:first-child {
            position: relative;
            top: 0;
            padding: 1.1rem 2rem 0;
        }

        .title {
            margin-bottom: 1rem;

            > a {
                margin-top: 0.5rem;
            }
        }

        .sub-title {
            font-weight: bold;
            margin-bottom: 1.7rem;
            margin-top: 1.3rem;
            cursor: pointer;
        }
    }

    h1 > a {
        color: #22232e;
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration: none;

        &:after {
            content: url(../../resources/images/arrow-black.svg);
            margin-left: 1rem;
        }
    }

    h2 {
        font-size: 1rem;
        font-weight: 700;

        > a {
            color: #22232e;
            font-weight: bold;
            text-decoration: none;

            &:after {
                content: url(../../resources/images/arrow-black.svg);
                margin-left: 1rem;
            }
        }
    }

    .sub-nav {
        margin-top: -.5rem;

        > li {
            margin: 0 1rem 1.5rem;
        }

        a {
            display: block;
            font-weight: 300;
            text-decoration: none;
            color: #22232e;
        }
    }

    > section > {
        ul > li {
            margin-bottom: 33px;
            margin-left: 0;
        }

        .lang {
            margin-top: 3rem;

            > li {
                margin-left: 0;
            }
        }
    }
}

.policy-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 450px;
  a {
    font-size: 14px;

  }
}
