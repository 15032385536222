.filter-params {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px;
  .filter-name {
    display: flex;
    flex-wrap: wrap;
    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.0120588em;
    color: #816F4B;
  }
  .filter-item {
    display: flex;
    padding: 10px 13px;

    background: rgba(129, 111, 75, 0.1);

    border: 1px solid #816F4B;
    box-sizing: border-box;
    border-radius: 30px;
    width: max-content;
    margin-right: 6px;
    margin-bottom: 6px;
    &:last-child {
      margin-right: 0;
    }
  }

  .close-parent {
    margin-left: 10px;
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .filter-params {
    justify-content: center;
  }
}
