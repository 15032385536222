@import "../../theme/colors";

.left-column {
    .security-form {
        width: 334px;
        margin: 46px auto;
        position: relative;

        .form {
            width: 100%;
            margin-top: 31px;

            .form-item {
                margin-bottom: 21px;
                position: relative;

                label {
                    font-size: 15px;
                    line-height: 16px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.0120588em;
                    color: #020202;
                }

                input {
                    height: 55px;
                    background: #F6F6F6;
                    border: 1px solid #E9E9E9;
                    box-sizing: border-box;
                    border-radius: 4px;
                    width: 100%;
                    margin-top: 9px;
                    padding: 16px 40px 16px 16px;
                    font-size: 15px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }

            .disabled {
                background: #EAEAEE;
                color: #979797;
            }

            .sign-in {
                font-size: 14px;
                line-height: 28px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0120588em;
                color: $additional-text-color;
                margin-top: 29px;

                label {
                    width: 176px;
                }

                a {
                    text-decoration-line: underline;
                    color: #006485;
                }
            }
        }

        .forgot-password {
            width: 100%;
            text-align: right;
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }

    > .security-form {
        .registration-help,
        .profile-link {
            font-size: 14px;
            line-height: 28px;
            color: $additional-text-color;
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
        }

        .profile-link {
            margin-top: 20px;
        }

        .login-links {
            width: 100%;
            display: flex;
            justify-items: center;
            justify-content: center;

            > a {
                width: 33.3%;
                text-align: center;

                &:first-child {
                    border-right: 1px solid $additional-text-color;
                }

                &:last-child {
                    border-left: 1px solid $additional-text-color;
                }
            }
        }
    }
}

.login-error {
    a {
        color: #000000;
    }

    background: rgba(251, 78, 78, 0.3);
    border-radius: 3px;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    width: 333px;
    height: 68px;
    display: table-cell;
}

@media (max-device-width: 1250px) {
    .left-column .security-form {
        width: 333px;
        margin: 37px auto;
        position: relative;
    }
}

.animated-gradient {
    background: repeating-linear-gradient(to right, #006485 0%, #397974 50%, #006485 100%) 0 100%;
    width: 100%;
    background-size: 200% auto;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
}

.security-form-description > .link {
    font-size: 16px;
}
