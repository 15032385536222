.flyout {
    position: fixed;
    top: 0;
    text-align: center;
    padding: 10px;
    width: 450px;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 99999;
}

.flyout-success {
    background-color: green;
    color: #ffffff;
}

.flyout-error {
    background-color: red;
    color: #ffffff;
}

@media (max-width: 1250px) {
    .flyout {
        width: 100%;
        left: 0;
    }
}
