@import "../../../theme/colors";

.admin-edit-menu {
  .menu-content {
    .menu-photo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 34px 0;
      border-top: 1px solid #EFEFEF;
      border-bottom: 1px solid #EFEFEF;
      .profile-image-wrapper {
        width: 147px;
        height: 147px;
        background-color: #717485;
        border-radius: 5px;
        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 5px;
        }
      }
      > p {
        font-size: 16px;
        font-family: "NunitoSans", sans-serif;
        color: $primary-text-color;
        font-weight: 700;
        line-height: 23px;
      }
    }
  }
  .app-survey-timeline {
    .admin-editor-timeline.enabled {
     > li.active {
       padding-top: 10px;
        margin: 0;
        background-color: #C4C4C466;
       > .title {
          color: #838386;
       }
      }

    }
    .hidden-timelines > li.active {
      &::before,
      &::after {
        content: ""
      }
    }
  }
  .timeline-survey-header {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 23px;
    text-transform: uppercase;
    display: flex;
    align-items: self-end;
    width: 245px;
    min-height: 25px;
    color: $secondary-text-color;
    position: relative;
    margin: 30px 40px;
  }
}