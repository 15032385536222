.progress-container {
  background: #EDECEC;
  border-radius: 5px;
  .progress {
    background-color: #007AFF;
    height: 3px;
    border-radius: 5px;
    transition: 1s ease;
  }
}

