.care-team {
    position: fixed;
    bottom: 0;
    top: 80px;
    border-left: 1px solid #EFEFEF;

    > {
        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 23px;
            color: #959595;
            display: block;
            text-transform: uppercase;
            margin-bottom: 27px;
        }

        .sub-title {
            font-size: 16px;
            line-height: 26px;
            color: #173432;
        }

        ul {
            margin-top: 16px;

            > li > .care-team-member {
                display: flex;

                > .image {
                    width: 38px;
                    height: 38px;
                    border: 1px dashed #D6D6D6;
                    box-sizing: border-box;
                    border-radius: 19px;
                }

                &:first-child {
                    margin-bottom: 26px;
                }

                > div:last-child {
                    margin-left: 14px;

                    > div {
                        &:first-child {
                            width: 100px;
                            height: 15px;
                            background: #F4F4F4;
                            margin-bottom: 5px;
                        }

                        &:last-child {
                            width: 60px;
                            height: 15px;
                            background: #F4F4F4;
                        }
                    }
                }
            }
        }

        .care-team-info {
            width: 149px;
            height: 52px;
            font-size: 16px;
            line-height: 26px;
            color: #173432;
            margin-top: 19px;
        }
    }
}
