@import "../../../theme/colors";

.note-item {
  font-family: "NunitoSans", sans-serif;
  border-radius: 5px;
  margin-bottom: 28px;
  .note-message {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #262626;
    white-space: pre-wrap;
    margin-bottom: 4px;
  }
  .note-header {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 4px;
    gap: 8px;
    .author-fullname {
      color: $black-text-color;
      font-weight: 700;
    }
    .note-time {
      font-weight: 400;
      color: #8C8C8C;
    }
  }
  .cancel-note-edit {
    position: absolute;
    right: 100px;
    bottom: 8%;
    width: 85px;
    height: 36px;
    padding: 7px 16px 7px 16px;
    border-radius: 30px;
    border: none;
    background-color: #8C8C8C;
    color: #fff;
  }
  .edit-note-container {
    display: flex;
    gap: 8px;
    > span {
      font-weight: 400;
      line-height: 20px;
      font-size: 15px;
      color: #816F4B;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}