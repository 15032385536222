.maintenance-container {
  .app-logo {
    padding: 17px 0 0 24px;
    width: 176px;
    height: 43px;
  }

  .maintenance-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;

    h1 {
      margin-bottom: 20px;
      font-size: 2em;
    }

    p {
      margin-bottom: 30px;
      font-size: 1.2em;
      line-height: 1.5;
    }

    .pin-tab-btn {
      background: none;
      border: 2px solid;
      padding: 10px 20px;
      cursor: pointer;
      font-size: 1em;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    &.surrogate {
      background: linear-gradient(180deg, #F8BBD0 0%, #711F43 100%);
      color: #ffffff;
    }

    &.parent {
      background: linear-gradient(180deg, #E0E0E0 0%, #715C40 100%);
      color: #ffffff;
    }
  }
}


@media (max-width: 1250px) {
  .maintenance-container {
    .app-logo {
      width: 185px;
      height: 41px;
      padding: 0;
    }
  }
}