footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f5f5f8;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  overflow: visible;
  z-index: 9;
  transform: translateY(100%);
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;

  &.show {
    transform: translateY(0);
    opacity: 1;
  }


  .footer-bottom {
    padding: 28px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .footer-bottom__left, .footer-bottom__right {
      font-size: 14px;
    }
  }
}

@media (max-width: 1250px) {
  footer {
    transition: none;
    transform: translateY(0);
    opacity: 1;
    .footer-bottom {
      padding: 20px;

      .footer-bottom__left, .footer-bottom__right {
        font-size: 14px;
      }
      &.show {
        transform: none;
        opacity: 1;
      }
    }
  }
}