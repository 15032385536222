.columns > .center-column {
    display: flex;
    justify-content: center;
}

.start-application > h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 41px;
    letter-spacing: 0.0120588em;
    color: #57575C;
    margin-top: 49px;
    margin-bottom: 39px;
}

h1.application-process-title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 41px;
    letter-spacing: 0.0120588em;
    color: #272727;
    margin-bottom: 26px;
}

.start-application > {
    .start-banner {
        width: 575px;
        min-height: 200px;
        border-radius: 8px;
        position: relative;
        cursor: pointer;
        padding-bottom: 20px;
    }

    .start-banner-green {
        background: radial-gradient(50% 50% at 50% 50%, #5F944E 0%, #69A457 100%);
    }

    .start-banner-blue {
        background: radial-gradient(50% 50% at 50% 50%, #94CAE4 0%, #81BBD7 100%);
    }

    .start-banner-grey {
        background: radial-gradient(50% 50% at 50% 50%, #717485 0%, #717485 100%);
    }

    .start-banner > div.bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../resources/images/start-banner-bg.svg);
    }

    .start-banner-blue > div.bg {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../resources/images/start_bg_dark_blue.svg);
        background-repeat: no-repeat!important;
    }

    .start-banner-grey > div.bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../../resources/images/start_banner_grey.svg);
    }

    .start-banner {
        padding-top: 25px;
        padding-left: 59px;
        padding-right: 60px;
        box-sizing: border-box;

        > {
            h1 {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 40px;
                letter-spacing: 0.0120588em;
                color: #FFFFFF;
            }

            p {
                position: inherit;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.0120588em;
                color: #FFFFFF;
                width: 286px;
                margin-top: 3px;
                margin-bottom: 10px;

                .embed-popup-button {
                    border: 0;
                    background: transparent;
                    text-decoration: underline;
                    color: #AD1B5F;
                    font-size: 16px;
                    padding: 10px 0 0 0;
                }
            }

            div.time {
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.0120588em;
                color: #FFFFFF;

                > label {
                    margin-left: 17px;
                }
            }
        }
    }

    .refer-button {
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
}

.schedule-button {
    border-radius: 36px;
    width: 307px;
    border: 0;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.0120588em;
    color: #FFFFFF;
    padding: 6px;
    font-weight: bold;

    &.surrogate {
        background: #A02C5E;
    }

    &.parent {
        background: #816F4B;
        text-decoration: none;
        padding: 9px 35px;

        &.reserve {
            display: block;
            box-sizing: border-box;
            margin: 20px auto;
            text-align: center;
        }
    }
}

.calendly-link-button {
    border-radius: 36px;
    width: 307px;
    height: 20px;
    border: 0;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.0120588em;
    color: #FFFFFF;
    padding: 10px 103px;
    font-weight: bold;
    text-decoration: none;

    &.surrogate {
        background: #A02C5E;
    }
}

@media (max-width: 1250px) {
    .start-application {
        width: 333px;

        > {
            .start-banner {
                width: 333px;
                min-height: 100px;

                > {
                    p {
                        width: 211px;
                    }

                    div.bg {
                        background: url(../../resources/images/start-banner-bg-mobile.svg) no-repeat;
                        background-position-y: bottom;
                    }
                }

                padding-left: 18px;
            }

            .start-banner-blue > div.bg {
                background: url(../../resources/images/start_bg_dark_blue_mobile.svg) no-repeat;
                background-position-y: bottom;
                background-position-x: 160px;
            }

            .start-banner-grey > div.bg {
                background: url(../../resources/images/start_bg_grey_mobile.svg) no-repeat;
                background-position-y: bottom;
                background-position-x: 170px;
            }

            .start-banner-green > div.bg {
                background: url(../../resources/images/start_bg_blue_mobile.svg) no-repeat;
                background-position-y: bottom;
                background-position-x: 160px;
            }

            .start-banner > div.time {
                margin-top: 24px;
            }
        }
    }

    .right-column-surrogate .care-team {
        display: block;
        margin-left: 0;
        margin-bottom: 40px;
    }

    .start-app-description .survey-process .app-survey-timeline {
        display: block;
    }

    .schedule-button.parent {
        padding: 6px;
    }

    .calendly-link-button {
        padding: 10px 69px;
    }
}

@media (max-width: 350px) {
    .start-application {
        > {
            h1 {
                text-align: center;
            }

            .start-banner {
                width: 290px;
            }
        }

        width: 290px;
    }

    .start-button {
        width: 290px;
    }

    .calendly-link-button {
        padding: 10px 66px;
    }

    .schedule-button {
        width: 205px;
    }
}
