.modal-dialog {
  .content {
    padding: 25px 16px 25px 16px;
    .form {
      width: calc(100% - 32px);

      .form-item {
        margin-bottom: 21px;
        position: relative;

        label {
          font-size: 15px;
          line-height: 16px;
          display: flex;
          align-items: center;
          letter-spacing: 0.0120588em;
          color: #020202;
        }

        input {
          height: 55px;
          background: #F6F6F6;
          border: 1px solid #E9E9E9;
          box-sizing: border-box;
          border-radius: 4px;
          width: 100%;
          margin-top: 9px;
          padding: 16px 40px 16px 16px;
        }
      }

      .password-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.0120588em;
        color: #959595 !important;
        text-align: left;
        margin-bottom: 15px;

        b {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .modal-dialog {
    width: calc(100% - 250px);

    .content {
      .form {
        width: 100%;
      }
    }
  }
}

@media (max-width: 800px) {
  .modal-dialog {
    width: calc(100% - 50px);

    .content {
      .form {
        width: 100%;
      }
    }
  }
}
