.scroll-link {
    font-family: "Nimbus Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    align-items: center;
    display: flex;
    letter-spacing: 0.0120588em;
    margin: 10px 0;
    user-select: none;
}

.scroll-link-surrogate {
    color: #A02C5E;
}

.scroll-link-parent {
    color: #816F4B;
}

.scroll-link-egg_donor {
    color: #1a7b74;
}
