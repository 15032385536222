@import "../../theme/colors";

.question-list {
    background: #FFFFFF;

    &.default {
        .active-section-container {
            background: #FFFFFF;
        }

        .button-section {
          background: #FAFAFA;
        }
    }

    &.register {
        .active-section-container {
            background: #FAFAFA;
        }

        .button-section {
            background: #FAFAFA;
        }
    }

    .section-title {
        font-weight: 600;
        font-size: 21px;
        margin: 30px 15px;
        text-align: center;
        height: 40px;
        color: $primary-text-color;
    }

    .question-list-container {
        border: 1px solid #F0F0F0;
        background: #FAFAFA;
        padding-bottom: 20px;

        .questions-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: auto;
            min-height:328px;;
            overflow-y: auto;
            overflow-x: hidden;

            .survey-question {
                width: 307px;
                box-sizing: border-box;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                justify-items: center;
                align-items: center;
                padding: 4px 0;
                margin: 4px 8px;
                position: relative;

                > {
                    .question-answers {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    .question-text {
                        width: 100%;
                        text-align: left;
                        font-size: 18px;
                        letter-spacing: 0.0120588em;
                        color: $primary-text-color;
                        padding-bottom: 9px;
                    }

                    .question-answers > button {
                        background: #F6F6F6;
                        border: 1px solid #E9E9E9;
                        box-sizing: border-box;
                        border-radius: 3px;
                        width: 307px;
                        margin-bottom: 16px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 28px;
                        letter-spacing: 0.0120588em;
                        color: $primary-text-color;
                        padding-top: 6px;
                        padding-bottom: 6px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    .help-text {
                        display: none;
                    }
                }

                input[type='text'] {
                    margin-bottom: 0;

                    &.masked {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    
    .active-section-container {
        display: flex;
        justify-content: center;
        padding-top: 30px;

        .section {
            height: 10px;
            width: 10px;
            background: #C4C4C4;
            border-radius: 50%;
            display: inline-block;
            margin: 0 2px;

            &.active {
                background: $surrogate-app-color;
            }
        }
    }

    .button-section {
        display: flex;
        height: 70px;

        .left-part {
            width: 25%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .previous {
                cursor: pointer;
                color: $surrogate-app-color;
            }

        }

        .center-part {
            width: 42%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            .spinner {
                width: 30px;
                height: 30px;
                fill: $surrogate-app-color;
                margin-right: 10px;
            }
        }

        .right-part {
            width: 33%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .next {
                cursor: pointer;
                color: $surrogate-app-color;
            }

            .finished {
                display: inline;
                cursor: pointer;
                background-color: $surrogate-app-color;
                border-radius: 36px;
                font-style: normal;
                font-size: 15px;
                line-height: 28px;
                letter-spacing: .0120588em;
                color: #fff;
                padding: 6px 10px;
                font-weight: 500;
                border: 0;

                &.disabled {
                    pointer-events: none;
                    color: $secondary-text-color;
                    background-color: #EAEAEE;
                }
            }
        }
    }
}

.phone-error, .email-error {
  padding: 15px 25px;
}

@media (max-width: 400px) {
    .question-list {
        .button-section {
            .center-part {
                width: 41%;

                .scroll-link {
                    font-size: 14px;
                }
            }

            .right-part {
                width: 34%;

                .finished {
                    font-size: 13px;
                    padding: 6px 5px;
                }
            }
        }
    }
}
