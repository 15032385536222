@import "../../theme/colors";

.left-column .registration-form {
  width: 345px;
  margin: 43px auto 46px;
  position: relative;

  .form {
    width: 100%;
    margin-top: 31px;

    .form-item {
      margin-bottom: 21px;
      position: relative;

      label {
        font-size: 15px;
        line-height: 16px;
        display: flex;
        align-items: center;
        letter-spacing: 0.0120588em;
        color: $primary-text-color;
      }

      input {
        height: 55px;
        background: #F6F6F6;
        border: 1px solid #E9E9E9;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        margin-top: 9px;
        padding: 16px 40px 16px 16px;
        font-size: 15px;
      }
    }

    .password-description {
      width: 100%;
      height: 100px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.024em;
      color: $additional-text-color !important;
      margin-top: -16px;
      margin-bottom: 15px;

      b {
        font-weight: bold;
      }
    }

    .join-button {
      background: $conab-primary-color;
      border-radius: 36px;
      width: 100%;
      height: 44px;
      font-size: 14px;
      letter-spacing: 0.0120588em;
      text-align: center;
      outline: none;
      border: 0;
      color: #FFFFFF;
      margin-top: 15px;
      &:focus {
        outline: 1px solid blue;
      }
    }

    .disabled {
      background: #EAEAEE;
      color: $additional-text-color;
    }

    .description-below {
      font-family: NunitoSans, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: $primary-text-color;
      margin-top: 20px;
      display: none;
    }

    .sign-in {
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.0120588em;
      color: $additional-text-color;
      margin-top: 29px;

      label {
        width: 176px;
      }

      a {
        text-decoration-line: underline;
        color: $conab-primary-color;
      }
    }
    .sign-in-parent {
      flex-direction: column;
      label {
        text-align: center;
        margin-bottom: 7px;
        width: 300px;
      }
    }
  }
}
.register-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background: rgba(251, 78, 78, 0.3);
  border-radius: 3px;
  font-size: 16px;
  line-height: 26px;
  color: $primary-text-color;
  min-height: 68px;
  max-height: max-content;
  padding: 0 5px;
  margin-bottom: 5px;

  a {
    color: $primary-text-color;
  }
}

@media (max-width: 1250px) {
  .left-column .registration-form {
    width: 333px;
    margin: 88px auto;
    position: relative;

    .description-wrapper {
      .second-paragraph {
        display: none;
      }
    }

    .form {
      .description-below {
        display: block;
      }
    }
  }
}

@media (max-width: 350px) {
  .left-column > .registration-form {
    width: 300px;
  }
}
