.sections-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .sections-dropdown-label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #816F4B;
  }

  .sections-dropdown-select {
    min-width: 325px;
    padding: 10px 15px;
    border-radius: 15px;
    border-color: #816F4B;
    font-size: 16px;
    color: #121212;
  }
}

@media (max-width: 1250px) {
  .questions-editor-section-dropdown {
    padding: 10px;
  }
  .sections-dropdown {
    width: 100%;
  }

  .sections-dropdown-select {
    width: 100%;
  }

}